import { FC } from 'react';
import { useUser } from 'features/common';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'features/common/variables/routes';
import { AdminDashboardTemplate } from './AdminDashboardTemplate';

export const DashboardTemplate: FC = () => {
  const { isAdmin } = useUser();

  return isAdmin ? <AdminDashboardTemplate /> : <Navigate to={ROUTES.myLegalCases} />;
};
