/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import { blue } from '@mui/material/colors';
import { Avatar } from '@mui/material';

// Material Dashboard 2 PRO React TS components
import MDAvatar from 'components/MDAvatar';

// @mui icons
import {
  Business,
  Reviews,
  Gavel,
  Quiz,
  DynamicFeed,
  Dashboard,
  TextSnippet,
  Toc,
  AccountBalance,
  Settings,
  Work,
  CalendarMonth,
  Chat,
  ManageAccounts,
  DashboardOutlined,
} from '@mui/icons-material';
import { AuthTemplate, RecoveryPasswordTemplate } from 'features/auth';
import { StaticPageListTemplate } from '../../static-pages/templates/StaticPageListTemplate';
import { CreateStaticPageTemplate } from 'features/static-pages';
import { ContentItemsTemplate } from 'features/content-items/templates/ContentItemsTemplate';
import { FAQListTemplate } from 'features/faq/templates/FAQListTemplate';
import { CreateContentItemTemplate } from 'features/content-items/templates/CreateContentItemTemplate';
import { EditContentItemTemplate } from 'features/content-items/templates/EditContentItemTemplate';
import { EditStaticPageTemplate } from 'features/static-pages/templates/EditStaticPageTemplate';
import {
  AttorneyStatesLawTypesTemplate,
  DocusignSignProcessCallbackController,
  UserProfileTemplate,
} from 'features/profile';
import { SecurityTemplate } from 'features/profile/templates/SecurityTemplate';
import { NotificationsControlTemplate } from 'features/profile/templates/NotificationsControlTemplate';
import { CaseLawTypeListTemplate } from 'features/case-law-types';
import { CaseLawTypeSuggestionsTemplate } from 'features/case-law-types/templates/CaseLawTypeSuggestionsTemplate';
import { CompanyListTemplate } from 'features/company-list/templates/CompanyListTemplate';
import { CompanyWaitingListTemplate } from 'features/company-list/templates/CompanyWaitingListTemplate';
import {
  CreateMyLegalCaseTemplate,
  ImportMyLegalCaseTemplate,
  ReviewMyLegalCaseTemplate,
} from 'features/my-legal-cases';
import {
  CompanyCaseLawTypesTemplate,
  CompanyInfoTemplate,
  CompanyPermissionsTemplate,
  CompanySubscriptionTemplate,
  CompanyWorkingHoursTemplate,
  MyCompanyTemplate,
  UsersCompanyTemplate,
} from 'features/my-company';
import { DashboardTemplate } from 'features/dashboard';
import { MyEventsTemplate } from 'features/my-events';
import { MyChatsTemplate } from 'features/my-chats';
import { LegalCasesFeedTemplate } from 'features/legal-cases-feed';
import { AttorneysLawFirms } from 'features/attorneys-law-firms';
import MDBadge from 'components/MDBadge';
import { ROUTES } from '../variables/routes';
import { useAppStatisticContextState } from 'features/contexts/AppStatisticsContextProvider';
import { ReviewTemplate } from 'features/review';
import { IntegrationsTemplate } from 'features/profile/templates/IntegrationsTemplate';
import { DocusignCallbackController } from '../../profile/components/DocusignCallbackController';
import {
  ClionIntegrationSettingsTemplate,
  GeneralSettingsTemplate,
  MailSettingsTemplate,
  SettingsTemplate,
  UrgentVersionsTemplate,
} from 'features/settings/templates';
import { CasesTemplate } from 'features/cases';
import { getAvatarCharacters } from '../helpers/utilities';
import { CaseDetailsTemplate } from 'features/case-details';
import { ClioCallBackController } from 'features/profile/components/ClioCallBackController';
import { ChatTemplate } from 'features/chat/ChatTemplate';
import {
  AdminsManagementTemplate,
  AttorneysManagementTemplate,
  ClientsManagementTemplate,
} from 'features/users-management';
import { SuccessPaymentTemplate } from 'features/subscriptions/templates/SuccessPaymentTemplate';
import { CancelPaymentTemplate } from 'features/subscriptions/templates/CancelPaymentTemplate';
import { AttorneyProSubscriptionTemplate } from 'features/subscriptions/templates/AttorneyProSubscriptionTemplate';
import { useUser } from './useUser';
import { useBoolean } from './useBoolean';
import {
  ClioCaseLawTypesMappingTemplate,
  ClioIntegrationTemplate,
  ClioSettingsTemplate,
} from 'features/clio-settings/templates';
import { NotFoundTemplate } from 'features/not-found';
import { AudioRoomTemplate, VideoRoomTemplate } from 'features/video-calling';
import { ResetPasswordTemplate } from 'features/reset-password';
import { VerifyEmailTemplate } from 'features/verify-email';
import { FirebaseSecurityTemplate } from 'features/firebase-security';
import { LockScreen } from 'features/LockScreen';
import { OpenAppTemplate } from 'features/open-app';
import { AdobeSignCallBackController } from 'features/profile/components/AdobeSignCallBackController';
import { IncominCallPush } from 'features/incoming-call-push';
import { SignEnd, SignSuccessProcessCallback } from 'features/sign-process';
import { VerifyEmailStep } from 'features/auth/components/VerifyEmailStep';

const getTabUserName = (personTitle = '', firstName = 'User', lastName = '') => {
  const fullUserName = `${personTitle} ${firstName} ${lastName}`;

  return fullUserName.length <= 20 ? fullUserName : fullUserName.split('').slice(0, 19).join('') + '...';
};

const allRoutes = [
  {
    route: ROUTES.signIn,
    component: <AuthTemplate authType="signIn" />,
  },
  {
    route: ROUTES.signUp,
    component: <AuthTemplate authType="signUp" />,
  },
  {
    route: ROUTES.adminSignUp,
    component: <AuthTemplate authType="signUp" />,
  },
  {
    route: ROUTES.authVerifyEmail,
    component: <VerifyEmailStep />,
  },
  {
    route: ROUTES.recoveryPassword,
    component: <RecoveryPasswordTemplate />,
  },
  {
    route: ROUTES.staticPages,
    component: <StaticPageListTemplate />,
  },
  {
    route: ROUTES.staticCreatePage,
    component: <CreateStaticPageTemplate />,
  },
  {
    route: ROUTES.staticPageDetails,
    component: <EditStaticPageTemplate />,
  },
  {
    route: ROUTES.contentItems,
    component: <ContentItemsTemplate />,
  },
  {
    route: ROUTES.contentItemCreateForCategory,
    component: <CreateContentItemTemplate />,
  },
  {
    route: ROUTES.contentItemDetails,
    component: <EditContentItemTemplate />,
  },
  {
    route: ROUTES.faq,
    component: <FAQListTemplate />,
  },
  {
    route: ROUTES.profile,
    component: <UserProfileTemplate />,
  },
  {
    route: ROUTES.security,
    component: <SecurityTemplate />,
  },
  {
    route: ROUTES.notifications,
    component: <NotificationsControlTemplate />,
  },
  {
    route: ROUTES.caseLawTypes,
    component: <CaseLawTypeListTemplate />,
  },
  {
    route: ROUTES.caseLawTypesSuggestions,
    component: <CaseLawTypeSuggestionsTemplate />,
  },
  {
    route: ROUTES.companyList,
    component: <CompanyListTemplate />,
  },
  {
    route: ROUTES.companyListSuggestions,
    component: <CompanyWaitingListTemplate />,
  },
  {
    route: ROUTES.myLegalCases,
    component: <CasesTemplate />,
  },
  {
    route: ROUTES.caseDetails,
    component: <CaseDetailsTemplate />,
  },
  {
    route: ROUTES.eventCalendar,
    component: <MyEventsTemplate />,
  },
  {
    route: ROUTES.conversations,
    component: <MyChatsTemplate />,
    children: <ChatTemplate />,
  },
  {
    route: ROUTES.legalCasesFeed,
    component: <LegalCasesFeedTemplate />,
  },
  {
    route: ROUTES.attorneysLawFirms,
    component: <AttorneysLawFirms />,
  },
  {
    route: ROUTES.home,
    component: <DashboardTemplate />,
  },
  {
    route: ROUTES.reviews,
    component: <ReviewTemplate />,
  },
  {
    route: ROUTES.integrations,
    component: <IntegrationsTemplate />,
  },
  {
    route: ROUTES.attorneyLawTypes,
    component: <AttorneyStatesLawTypesTemplate />,
  },
  {
    route: ROUTES.docusignRedirect,
    component: <DocusignCallbackController />,
  },
  {
    route: ROUTES.adobeSignRedirect,
    component: <AdobeSignCallBackController />,
  },
  {
    route: ROUTES.createLegalCase,
    component: <CreateMyLegalCaseTemplate />,
  },
  {
    route: ROUTES.clioCallback,
    component: <ClioCallBackController />,
  },
  {
    route: ROUTES.incominCallPush,
    component: <IncominCallPush />,
  },
  {
    route: ROUTES.caseReview,
    component: <ReviewMyLegalCaseTemplate />,
  },
  { route: ROUTES.legalCaseChat, component: <ChatTemplate /> },
  {
    route: ROUTES.attorneyManagement,
    component: <AttorneysManagementTemplate />,
  },
  {
    route: ROUTES.clientManagement,
    component: <ClientsManagementTemplate />,
  },
  {
    route: ROUTES.settingsGeneral,
    component: <SettingsTemplate />,
    childrens: [
      { route: ROUTES.settingsGeneral, component: <GeneralSettingsTemplate /> },
      { route: ROUTES.settingsMail, component: <MailSettingsTemplate /> },
      { route: ROUTES.settingsClioIntegration, component: <ClionIntegrationSettingsTemplate /> },
      { route: ROUTES.settingsUrgentVersions, component: <UrgentVersionsTemplate /> },
    ],
  },
  {
    route: ROUTES.firmProfile,
    component: <MyCompanyTemplate />,
    childrens: [
      { route: ROUTES.firmProfile, component: <CompanyInfoTemplate /> },
      { route: ROUTES.myCompanyCaseLawTypes, component: <CompanyCaseLawTypesTemplate /> },
      { route: ROUTES.myCompanyUsers, component: <UsersCompanyTemplate /> },
      { route: ROUTES.myCompanyWorkingHours, component: <CompanyWorkingHoursTemplate /> },
      { route: ROUTES.myCompanyPermissions, component: <CompanyPermissionsTemplate /> },
      {
        route: ROUTES.myCompanySubscriptions,
        component: <CompanySubscriptionTemplate />,
      },
    ],
  },
  {
    route: ROUTES.clioSettings,
    component: <ClioIntegrationTemplate />,
    childrens: [
      { route: ROUTES.clioSettings, component: <ClioSettingsTemplate /> },
      { route: ROUTES.clioCaseLawTypesMapping, component: <ClioCaseLawTypesMappingTemplate /> },
    ],
  },
  {
    route: ROUTES.adminManagement,
    component: <AdminsManagementTemplate />,
  },
  {
    route: ROUTES.videoRoom,
    component: <VideoRoomTemplate />,
  },
  {
    route: ROUTES.audioRoom,
    component: <AudioRoomTemplate />,
  },
  {
    route: ROUTES.docusignSignProccessCallback,
    component: <DocusignSignProcessCallbackController />,
  },
  {
    route: ROUTES.subscription,
    component: <AttorneyProSubscriptionTemplate />,
  },
  {
    route: ROUTES.successPayment,
    component: <SuccessPaymentTemplate />,
  },
  {
    route: ROUTES.cancelPayment,
    component: <CancelPaymentTemplate />,
  },
  { route: ROUTES.importCRMCase, component: <ImportMyLegalCaseTemplate /> },

  { route: ROUTES.signProcessCompleteRedirect, component: <SignSuccessProcessCallback /> },
  { route: ROUTES.fileSignEnd, component: <SignEnd /> },

  { route: ROUTES.resetPassword, component: <ResetPasswordTemplate /> },

  { route: ROUTES.verifyUserEmail, component: <VerifyEmailTemplate /> },
  { route: ROUTES.openApp, component: <OpenAppTemplate /> },

  { route: ROUTES.firebaseSecurity, component: <FirebaseSecurityTemplate /> },

  { route: ROUTES.lockScreen, component: <LockScreen /> },

  { route: ROUTES.notFound, component: <NotFoundTemplate /> },
];

export const useRoutes = () => {
  const { adminStatistic, userStatistic } = useAppStatisticContextState();
  const { isNavigationBlocked, backendUser, isAdmin, isAttorney, isClient, logout } = useUser();

  const [isLogoutWindow, openLogoutWindow, closeLogOutWindow] = useBoolean(false);

  const { userProfile } = backendUser || {};
  const isAuthenticationSectionVisible = Boolean(backendUser);

  const sidenavRoutes = [
    { type: 'divider', key: 'divider-0' },

    isAuthenticationSectionVisible && {
      type: 'collapse',
      name: getTabUserName(userProfile?.personTitle || '', userProfile?.firstName, userProfile?.lastName),
      key: 'profile',
      icon: backendUser?.logo ? (
        <MDAvatar
          src={backendUser?.logo?.publicUrl}
          size="xl"
          variant="circular"
          sx={{ background: '#d3d3d3', objectFit: 'contain', width: '32px', height: '32px' }}
        />
      ) : (
        <Avatar sx={{ width: '32px', height: '32px', bgcolor: blue[500], fontSize: '14px' }}>
          {getAvatarCharacters(backendUser?.userProfile?.firstName, backendUser?.userProfile?.lastName)}
        </Avatar>
      ),
      collapse: [
        {
          type: 'button',
          name: 'Profile',
          key: 'profile',
          route: ROUTES.profile,
        },
        {
          type: 'button',
          name: 'Logout',
          key: 'logout',
          action: openLogoutWindow,
        },
      ],
    },
    ...(isClient
      ? [
          { type: 'divider', key: 'divider-1' },
          {
            type: 'collapse',
            name: 'My Cases',
            key: 'cases',
            route: ROUTES.myLegalCases,
            icon: <Work fontSize="small" />,
            component: <CasesTemplate />,
            noCollapse: true,
          },
          COMMON_SIDENAV_ROUTES.myEvents,
          COMMON_SIDENAV_ROUTES.myChats(userStatistic?.newChatMessageCount),
        ]
      : []),

    ...(isAttorney
      ? [
          { type: 'divider', key: 'divider-1' },
          {
            type: 'collapse',
            name: 'My Cases',
            key: 'cases',
            route: ROUTES.myLegalCases,
            icon: <Work fontSize="small" />,
            component: <CasesTemplate />,
            noCollapse: true,
            isDisabled: isNavigationBlocked,
          },
          { ...COMMON_SIDENAV_ROUTES.myEvents, isDisabled: isNavigationBlocked },
          { ...COMMON_SIDENAV_ROUTES.myChats(userStatistic?.newChatMessageCount), isDisabled: isNavigationBlocked },
          {
            type: 'collapse',
            name: 'Case Feed',
            key: 'case-feed',
            route: ROUTES.legalCasesFeed,
            icon: <DynamicFeed fontSize="small" />,
            component: <LegalCasesFeedTemplate />,
            noCollapse: true,
            isDisabled: isNavigationBlocked,
          },
          {
            type: 'collapse',
            name: 'Firm Profile',
            key: 'firm-profile',
            route: ROUTES.firmProfile,
            icon: <AccountBalance fontSize="small" />,
            component: <MyCompanyTemplate />,
            noCollapse: true,
          },
        ]
      : []),

    ...(isAdmin
      ? [
          { type: 'divider', key: 'divider-3' },
          { type: 'title', title: 'Admin area', key: 'admin-area' },
          {
            type: 'collapse',
            name: 'Dashboard',
            key: 'dashboard',
            route: ROUTES.home,
            icon: (
              <MDBadge badgeContent={adminStatistic?.feedNotViewedCount} color="error" size="xs" circular>
                <DashboardOutlined fontSize="small" />
              </MDBadge>
            ),
            component: <DashboardTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            name: 'Content Items',
            key: 'content-items',
            route: ROUTES.contentItems,
            icon: <Toc fontSize="small" />,
            component: <ContentItemsTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            key: 'static',
            name: 'Content Pages',
            route: ROUTES.staticPages,
            icon: <TextSnippet />,
            component: <StaticPageListTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            key: 'faq',
            name: 'FAQ',
            route: ROUTES.faq,
            icon: <Quiz />,
            component: <FAQListTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            key: 'case-law-types',
            name: 'Case Law Types',
            route: ROUTES.caseLawTypes,
            icon: (
              <MDBadge badgeContent={adminStatistic?.caseLawTypeWaitingForSuggestion} color="error" size="xs" circular>
                <Gavel />
              </MDBadge>
            ),
            component: <CaseLawTypeListTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            key: 'companies',
            name: 'Companies',
            route: ROUTES.companyList,
            icon: (
              <MDBadge badgeContent={adminStatistic?.companiesWaitingForApproval} color="error" size="xs" circular>
                <Business />
              </MDBadge>
            ),
            component: <CompanyListTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            key: 'users-management',
            name: 'Users Management',
            route: ROUTES.attorneyManagement,
            icon: <ManageAccounts />,
            component: <AttorneysManagementTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            key: 'reviews',
            name: 'Reviews',
            route: ROUTES.reviews,
            icon: (
              <MDBadge badgeContent={adminStatistic?.reviewsWaitingForApproval} color="error" size="xs" circular>
                <Reviews />
              </MDBadge>
            ),
            component: <ReviewTemplate />,
            noCollapse: true,
          },
          {
            type: 'collapse',
            key: 'settings',
            name: 'Settings',
            route: ROUTES.settingsGeneral,
            icon: <Settings />,
            component: <SettingsTemplate />,
            noCollapse: true,
          },
        ]
      : []),
  ].filter(route => Boolean(route));

  return {
    appRoutes: allRoutes,
    sidenavRoutes,
    isLogoutWindow,
    closeLogOutWindow,
    logout,
  };
};

const COMMON_SIDENAV_ROUTES = {
  myEvents: {
    type: 'collapse',
    name: 'Event Calendar',
    key: 'event-calendar',
    route: ROUTES.eventCalendar,
    icon: <CalendarMonth fontSize="small" />,
    component: <MyEventsTemplate />,
    noCollapse: true,
  },
  myChats: (amount: number) => ({
    type: 'collapse',
    name: 'Conversations',
    key: 'conversations',
    route: ROUTES.conversations,
    icon: (
      <MDBadge badgeContent={amount} color="error" size="xs" circular>
        <Chat fontSize="small" />
      </MDBadge>
    ),
    component: <MyChatsTemplate />,
    noCollapse: true,
  }),
};
