import { FC, useCallback, useEffect, useState } from 'react';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { shallow } from 'zustand/shallow';
import { ToastType, controller, createBackendUserService, notice, useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { useLockScreen } from 'features/store';
import { firebaseAuth } from 'features/auth';
import { resetters } from 'features/store/createStore';

const DEFAULT_IDLE_TIMEOUT_VALUE = 300000;

const NOT_ALLOWED_ROUTES = [
  ROUTES.lockScreen,
  ROUTES.signIn,
  ROUTES.signUp,
  ROUTES.recoveryPassword,
  ROUTES.resetPassword,
  ROUTES.firebaseSecurity,
  ROUTES.verifyUserEmail,
  ROUTES.adminSignUp,
  ROUTES.docusignSignProccessCallback,
  ROUTES.docusignRedirect,
  ROUTES.subscription,
  ROUTES.successPayment,
  ROUTES.cancelPayment,
  ROUTES.videoRoom,
  ROUTES.audioRoom,
  ROUTES.incominCallPush,
];

// TODO remove after testing
// const USERS_STATE_NAMES = [
//   'audioVideoChat',
//   'myLegalCases',
//   'myClients',
//   'caseDetails',
//   'myChats',
//   'myCompany',
//   'myEvents',
//   'legalCasesFeed',
// ];
// const ADMIN_STATE_NAMES = ['adminSettings', 'userReviews'];
// const { logoutBackendUser } = createBackendUserService();

const ONE_DAY_IN_MILLISEC = 1 * 24 * 60 * 60 * 1000;

export const LockScreenGuard: FC = (): null => {
  const router = useRouter();

  const { isPinCodeActive: isShouldUseLockScreen, isAdmin, firebaseNotificationToken } = useUser();

  const {
    lastIdleConfigExecutionTime,
    onGetTimeToIdleHandler,
    registerDeviceHandler,
    isRedirectToLockScreen,
    idleConfigValue,
    deviceId,
    isStopIdleTimer,
    setIsDeactivateIdleTimer,
    lastActivity,
    setActivityTime,
    onIdle,
  } = useLockScreen(
    state => ({
      isRedirectToLockScreen: state.isRedirectToLockScreen,
      idleConfigValue: state.idleConfigData?.configValue,
      deviceId: state.deviceId,
      onGetTimeToIdleHandler: state.onGetTimeToIdleHandler,
      registerDeviceHandler: state.registerDeviceHandler,
      lastIdleConfigExecutionTime: state.lastIdleConfigExecutionTime,
      isStopIdleTimer: state.isDeactivateIdleTimer,
      setIsDeactivateIdleTimer: state.setIsDeactivateIdleTimer,
      setActivityTime: state.setActivityTime,
      lastActivity: state.lastActivity,
      onIdle: useCallback(
        (isAdmin: boolean, firebaseNotificationToken: string) =>
          state.onIdleHandler(isAdmin, firebaseNotificationToken),
        []
      ),
    }),
    shallow
  );

  const idleValueInMS = Number(idleConfigValue) * 1000 || DEFAULT_IDLE_TIMEOUT_VALUE;

  const checkIsRouteValid = useCallback(() => {
    return !NOT_ALLOWED_ROUTES.includes(router?.pathname as ROUTES);
  }, [router.pathname]);

  // TODO remove after testing
  // const onIdleHandler = useCallback(async () => {
  //   setIsRedirectToLockScreen(true);
  //   try {
  //     await logoutBackendUser();
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   await firebaseAuth.signOut();

  //   const stateNames = isAdmin ? ADMIN_STATE_NAMES : USERS_STATE_NAMES;

  //   resetters.forEach(resetter => {
  //     if (stateNames.includes(resetter?.name)) resetter?.resetFunction();
  //   });
  // }, [isAdmin]);

  const onIdleHandler = useCallback(async () => {
    await onIdle(isAdmin, firebaseNotificationToken);
    router.navigate(ROUTES.lockScreen);
  }, [isAdmin, firebaseNotificationToken]);

  const onActionHandler = useCallback((event: Event, idleTimer: IIdleTimer) => {
    setActivityTime(Date.now());
  }, []);

  const { start, pause, getRemainingTime } = useIdleTimer({
    onIdle: onIdleHandler,
    timeout: idleValueInMS,
    debounce: 300,
    crossTab: true,
    name: 'lockScreenGuard',
    startManually: true,
    stopOnIdle: true,
    onAction: onActionHandler,
  });

  useEffect(() => {
    if (!isShouldUseLockScreen) return;
    if (!checkIsRouteValid()) return;

    const diff = Date.now() - lastActivity;

    if (lastActivity && diff > idleValueInMS && !isRedirectToLockScreen) {
      setActivityTime(Date.now());

      onIdleHandler();
    } else {
      setActivityTime(Date.now());
    }
  }, [isShouldUseLockScreen, router.pathname]);

  const onGetTimerData = async () => {
    if (!checkIsRouteValid()) return;

    const promises: Promise<void>[] = [];

    if (!idleConfigValue && isShouldUseLockScreen) {
      promises.push(onGetTimeToIdleHandler());
    }

    if (
      idleConfigValue &&
      isShouldUseLockScreen &&
      lastIdleConfigExecutionTime &&
      Date.now() - parseInt(lastIdleConfigExecutionTime, 10) >= ONE_DAY_IN_MILLISEC
    ) {
      promises.push(onGetTimeToIdleHandler());
    }
    if (!deviceId && isShouldUseLockScreen) promises.push(registerDeviceHandler());

    try {
      if (promises.length) await Promise.allSettled(promises);
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  };

  useEffect(() => {
    onGetTimerData();
  }, [router.pathname]);

  useEffect(() => {
    if (!isShouldUseLockScreen) return;

    if (isRedirectToLockScreen && checkIsRouteValid()) {
      controller.abort();
    }
  }, [router.pathname, isRedirectToLockScreen, isShouldUseLockScreen]);

  // START / PAUSE TIMER and Redirect back to lock screen
  const isStartTimer = checkIsRouteValid() && isShouldUseLockScreen && !isRedirectToLockScreen;

  const isNavigateToLockScreen = checkIsRouteValid() && isRedirectToLockScreen && isShouldUseLockScreen;

  const isPauseTimer = !isRedirectToLockScreen && isShouldUseLockScreen && !checkIsRouteValid() && getRemainingTime();

  useEffect(() => {
    if (isStartTimer) {
      start();
    }

    if (isNavigateToLockScreen) {
      router.navigate(ROUTES.lockScreen, { state: { prevRoute: router.pathname } });
    }

    if (isPauseTimer) {
      pause();
    }
  }, [isStartTimer, router.pathname]);

  useEffect(() => {
    if (isRedirectToLockScreen && isShouldUseLockScreen && getRemainingTime() && checkIsRouteValid()) {
      pause();
    }

    if (!isShouldUseLockScreen && isStopIdleTimer) {
      pause();
      setIsDeactivateIdleTimer(false);
    }
  }, [isStopIdleTimer, isShouldUseLockScreen, isRedirectToLockScreen]);

  return null;
};
