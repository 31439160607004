import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { notice, ToastType, useAuthentication } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import { SignUpPhoneFormSchema, signUpPhoneFormValidationSchema } from 'features/auth/formSchema';
import { AUTH_FLOW_STEPS } from 'features/store';
import MDBox from 'components/MDBox';
import { PhoneField } from 'features/common/components/PhoneField';
import { isPhoneValid } from 'features/common/helpers/utilities';
import { AuthProvidersButtons } from './AuthProvidersButtons';
import { RedirectButton } from './RedirectButton';
import { useCaptchaVerifier } from '../contexts';
import { createFirebaseService } from '../services';

type FirebaseSignUpWithEmailProps = {
  isSignIn?: boolean;
};

const { sendVerificationCodeOnPhoneNumber } = createFirebaseService();

export const FirebaseAuthWithPhone: FC<FirebaseSignUpWithEmailProps> = ({ isSignIn }) => {
  const { onAuthProviderButtonClickHandler, setData } = useAuthentication();

  const { firebaseAppVerifier } = useCaptchaVerifier();

  const {
    control,
    setError,
    formState: { isDirty, isValid, errors, isSubmitting },
    handleSubmit,
  } = useForm<SignUpPhoneFormSchema>({
    defaultValues: { phone: '' },
    resolver: yupResolver(signUpPhoneFormValidationSchema),
    mode: 'onChange',
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    try {
      const isValid = isPhoneValid(formData.phone);

      if (!isValid) {
        setError('phone', { message: 'Enter a valid phone number' });
        return;
      }

      const confirmationResult = await sendVerificationCodeOnPhoneNumber(formData.phone, firebaseAppVerifier);
      if (confirmationResult) {
        notice(ToastType.SUCCESS, "We've sent verification message to your phone!", { autoClose: false });

        setData({
          currentStep: AUTH_FLOW_STEPS.FIREBASE_AUTH,
          data: { verificationId: confirmationResult.verificationId, phoneNumber: formData.phone },
          activeStep: AUTH_FLOW_STEPS.VERIFY_PHONE,
        });
      }
    } catch (error) {
      console.error(error);
    }
  });

  const isNextButtonDisabled = !isValid || isSubmitting || !isDirty;

  return (
    <form onSubmit={onFormSubmitHandler}>
      <MDBox>
        <Controller
          control={control}
          name="phone"
          render={({ field }) => {
            const onChange = (value: string) => field.onChange(value);
            return (
              <PhoneField {...field} onChange={onChange} label="Phone number" errorValue={errors['phone']?.message} />
            );
          }}
        />

        <AuthProvidersButtons
          isNextButtonDisabled={isNextButtonDisabled}
          isProviderButtonDisabled={isSubmitting}
          onAuthProviderButtonClickHandler={onAuthProviderButtonClickHandler(!isSignIn)}
          isSignIn={isSignIn}
        />
        <RedirectButton routeTo={isSignIn ? ROUTES.signUp : ROUTES.signIn} />
      </MDBox>
    </form>
  );
};
