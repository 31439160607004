import { AxiosError } from 'axios';
import { BASE_ERROR_MESSAGE } from './paymentRequiredErrorHandler';
import { ToastType, notice } from '../modules';

export const abortRequestErrorHandler = (error: unknown, baseErrorMessage: string = BASE_ERROR_MESSAGE) => {
  if (error instanceof AxiosError) {
    return error.code === 'ERR_CANCELED' ? null : notice(ToastType.ERROR, baseErrorMessage);
  } else {
    return notice(ToastType.ERROR, baseErrorMessage);
  }
};
