/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from 'react';

// react-router components
import { Routes, Route, useLocation } from 'react-router-dom';

// @mui material components
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Material Dashboard 2 PRO React TS exampless
import Sidenav from 'examples/Sidenav';

// Material Dashboard 2 PRO React TS themes
import theme from 'assets/theme';

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from 'assets/theme-dark';

import { ToastNotification, ToastType, notice, useRoutes, useUser } from 'features/common';

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav } from 'context';

// Images
import brandWhite from './assets/images/logo-lb-light.svg';
import brandDark from './assets/images/logo-lb-dark.svg';
import { AccessGuard, InterceptorGuard } from './features/auth';
import { CaptchaContextProvider } from './features/auth/contexts/CaptchaContextProvider';
import { AppStatisticContextProvider } from 'features/contexts/AppStatisticsContextProvider';
import { CallInvitationPopup } from 'features/notification-center/Components/CallInvitationPopup';
import { AgoraAudioVideoIncomingCallData } from 'features/common/services/agoraEventApiService';
import { AuthState, LockScreenState, useLockScreen, useNotificationsCenter } from 'features/store';
import { useAudio } from 'features/common/hooks/useAudio';
import callTrack from './assets/sounds/call.mp3';
import { ReviewModal } from 'features/video-calling/Components/ReviewModal';
import { MessagePayload } from 'firebase/messaging';
import { FirebaseUserContextProvider } from 'features/auth/contexts/FirebaseUserContextProvider';
import { BaseFavicon } from 'features/common/components/BaseFavicon';
import { ROUTES } from 'features/common/variables/routes';
import { messaging, onMessageListener } from 'features/auth/firebase';
import { LockScreenGuard } from 'features/lock-screen-guard';
import { ThemeProvider } from 'features/common/theme/ThemeProvider';
import { shallow } from 'zustand/shallow';
import { SubscriptionModalsContextProvider } from 'features/contexts/SubscriptionModalsContextProvider';

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();
  const { backendUserFirebaseId, setStateData, retrieveTokenHandler } = useUser();

  const { appRoutes } = useRoutes();
  const { inviteCallData, setCallInvitationMessage, isCallModalOpen, setIsCallModalOpen } = useNotificationsCenter(
    state => ({
      setCallInvitationMessage: state.setCallInvitationMessage,
      inviteCallData: state.pushNotificationData?.inviteCallData,
      isCallModalOpen: state.isCallModalOpen,
      setIsCallModalOpen: state.setIsCallModalOpen,
    }),
    shallow
  );

  const isRedirectToLockScreen = useLockScreen(state => state.isRedirectToLockScreen);

  const handleCallAlreadyAccepted = () => {
    toggleStop();
    setIsCallModalOpen(false);
  };

  const onOpenCallModal = () => {
    setIsCallModalOpen(true);
  };

  const onCloseCallModalHandler = () => {
    setIsCallModalOpen(false);
    toggleStop();
  };

  const { togglePlay, toggleStop } = useAudio(callTrack);

  onMessageListener()?.(messaging, payload => {
    const data = payload?.data;

    if (data.type === 'service_login') {
      if (window.location.pathname === ROUTES.lockScreen || isRedirectToLockScreen) return;
      retrieveTokenHandler({ secretCode: data?.secretCode });
      return;
    }

    if (data?.incomingCallAccepted) {
      handleCallAlreadyAccepted();
      return;
    }

    if (
      (pathname === ROUTES.conversations && data?.type === 'chat_transition') ||
      (pathname === ROUTES.legalCaseChat && data?.type === 'chat_transition')
    )
      return;

    if (data?.missedCall) {
      isCallModalOpen && onCloseCallModalHandler();
      notice(ToastType.INFO, payload?.notification?.body);
      return;
    }
    if (
      (data?.agoraEventType === 'video_call' && !data?.manualCallCancel) ||
      (data?.agoraEventType === 'voice_call' && !data?.manualCallCancel)
    ) {
      if (!data?.callCancel) {
        setCallInvitationMessage(data as AgoraAudioVideoIncomingCallData);

        onOpenCallModal();
        togglePlay();
        return;
      } else {
        if (data?.callCancel && data?.callerFirebaseUuid === backendUserFirebaseId) {
          onCloseCallModalHandler();
          return;
        }

        (pathname === ROUTES.videoRoom || pathname === ROUTES.audioRoom) &&
          setCallInvitationMessage(data as AgoraAudioVideoIncomingCallData);

        isCallModalOpen && onCloseCallModalHandler();
        const message = `${data?.callerFirstName} ${data?.callerLastName} declined the call. Try to call later.`;
        notice(ToastType.INFO, message);
        return;
      }
    }

    if (
      (data?.agoraEventType === 'video_call' && !!data?.manualCallCancel) ||
      (data?.agoraEventType === 'voice_call' && !!data?.manualCallCancel)
    ) {
      (pathname === ROUTES.videoRoom || pathname === ROUTES.audioRoom) &&
        setCallInvitationMessage(data as AgoraAudioVideoIncomingCallData);

      isCallModalOpen && onCloseCallModalHandler();
    }
    notice(ToastType.INFO, payload?.notification?.body);
  });

  useEffect(() => {
    const listener = (event: MessageEvent<MessagePayload & { type: 'incomingCall' | 'cancelCall' }>) => {
      const { data, type } = event.data;
      if (type === 'incomingCall') {
        setCallInvitationMessage(data as AgoraAudioVideoIncomingCallData);
        onOpenCallModal();
      }

      if (type === 'cancelCall') {
        setIsCallModalOpen(false);
      }
    };

    navigator?.serviceWorker?.addEventListener('message', listener);

    return () => {
      navigator?.serviceWorker?.removeEventListener('message', listener);
    };
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // TODO remove after testing
  // const updateState = useLockScreen(state => state.updateState);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'auth') {
        const parsedState: { state: AuthState } = JSON.parse(event?.newValue || 'null');

        if (!parsedState?.state?.backendUser && !parsedState?.state?.isAuthorized) {
          setStateData('backendUser', null);
          setStateData('isAuthorized', false);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const App = (
    <>
      <BaseFavicon />
      {layout === 'dashboard' && (
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <InterceptorGuard />
      <LockScreenGuard />

      <Routes>
        {appRoutes.map(route => (
          <Route path={route.route} element={route.component} key={route.route}>
            {route?.childrens &&
              route.childrens.map(el => <Route key={el.route} path={el.route} element={el.component} />)}
          </Route>
        ))}
      </Routes>

      <CallInvitationPopup
        isOpen={isCallModalOpen}
        onToggleSoundHandler={toggleStop}
        onClosehandler={onCloseCallModalHandler}
        userCallerFirstName={inviteCallData?.callerFirstName}
        userCallerLastName={inviteCallData?.callerLastName}
        userCallerLogoURL={inviteCallData?.callerLogo}
        userCallerFirebaseId={inviteCallData?.callerFirebaseUuid}
        eventId={inviteCallData?.agoraEvent}
        eventType={inviteCallData?.agoraEventType}
      />
      <ReviewModal />
    </>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <FirebaseUserContextProvider>
        <CaptchaContextProvider>
          <AppStatisticContextProvider>
            {/* TODO conference hack */}
            <SubscriptionModalsContextProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AccessGuard>{App}</AccessGuard>
              </LocalizationProvider>
              <ToastNotification />
            </SubscriptionModalsContextProvider>
          </AppStatisticContextProvider>
        </CaptchaContextProvider>
      </FirebaseUserContextProvider>
    </ThemeProvider>
  );
}
