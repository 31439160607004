import { Card } from '@mui/material';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { PinCodeForm } from './PinCodeForm';
import { CONFIG_ID } from 'features/store';
import { useEffect, useState } from 'react';
import { settingsApiService } from 'features/settings/SettingsApiService';
import { baseBackendErrorHandler } from 'features/common/errorHanders';

export const PIN_CODE_REQUIREMENTS = ['4 digits long'];

const { getConfigById } = settingsApiService();

export const PinCode: React.FC = () => {
  const [idleValueInMinutes, setIdleValueInMinutes] = useState(5);

  const onGetIdleTimeValue = async () => {
    try {
      const { data } = await getConfigById(CONFIG_ID);
      setIdleValueInMinutes(Number(data?.configValue) / 60);
    } catch (error) {
      console.error(error);
      baseBackendErrorHandler(error);
    }
  };

  useEffect(() => {
    onGetIdleTimeValue();
  }, []);

  return (
    <Card id="change-password">
      <MDBox p={3}>
        <MDTypography variant="h5">PIN code</MDTypography>
        <MDTypography variant="body2" color="text">
          {`
          The lock screen will activate automatically after ${idleValueInMinutes} minute${
            idleValueInMinutes > 1 ? 's' : ''
          } of inactivity to help protect your data. This
          ensures that your personal information remains secure, even when your device is unattended.
          `}
        </MDTypography>
      </MDBox>
      <PinCodeForm />
    </Card>
  );
};
