import { useCallback } from 'react';
import { createFirebaseService, OAUTH_PROVIDER_TYPES } from 'features/auth';
import { useFirebaseUser } from 'features/auth/contexts/FirebaseUserContextProvider';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import { notice, ToastType } from '../modules';
import { createBackendUserService } from '../services';
import { useRouter } from './useRouter';
import { ROUTES } from '../variables/routes';
import { User } from 'firebase/auth';

const { verifyUsersEmail, authWithPopupByProvider } = createFirebaseService();

export const useAuthentication = () => {
  const { getBackendUser } = createBackendUserService();

  const { firebaseUser } = useFirebaseUser();

  const {
    backendUser,
    invitedProfile,
    resetState,
    setData,
    workType,
    setBackendUser,
    resetAllStepsData,
    toStep,
    acceptAdminInvitationHandler,
    setStateData,
    firebasePhoneAuthData,
  } = useAuth(state => ({
    setBackendUser: state.setBackendUser,
    toStep: state.toStep,
    acceptAdminInvitationHandler: useCallback(state.acceptAdminInvitationHandler, []),
    resetAllStepsData: state.resetAllStepsData,
    setData: state.setData,
    setStateData: state.setStateData,
    workType: state.workType,
    resetState: state.resetState,
    invitedProfile: state.invitationUser,
    backendUser: state.backendUser,
    firebasePhoneAuthData: state.firebasePhoneAuthData,
  }));

  const { push, params } = useRouter();

  const onSendConfirmationEmailHandler = useCallback(
    async ({ isDisableNotice, firebaseUser }: { firebaseUser: User | null; isDisableNotice?: boolean }) => {
      if (!firebaseUser) return;

      await verifyUsersEmail(
        firebaseUser,
        isDisableNotice ? '' : 'Verification link has been sent to your email. Please check your inbox.'
      );
    },
    []
  );

  const onConnectBackendUser = useCallback(
    async ({ user }: { user: User | null }) => {
      const isEmailVerified = user?.emailVerified;

      try {
        const backendUser = await getBackendUser();

        if (backendUser) {
          const isAdmin = backendUser?.roles?.includes('ROLE_ADMIN');
          setBackendUser(backendUser);
          resetAllStepsData();

          if (isEmailVerified) {
            setStateData('isAuthorized', true);
            push(isAdmin ? ROUTES.home : ROUTES.myLegalCases).then(() => {
              notice(ToastType.SUCCESS, `Welcome!`);
            });
          } else {
            push(ROUTES.authVerifyEmail);
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) return toStep(AUTH_FLOW_STEPS.WORK_TYPE);

        notice(ToastType.ERROR, 'Something went wrong, please try again!');
      }
    },
    [onSendConfirmationEmailHandler]
  );

  const onAuthProviderButtonClickHandler = useCallback(
    (isSendEmailVerification?: boolean) => async (event: React.MouseEvent<HTMLButtonElement>) => {
      try {
        const userCredential = await authWithPopupByProvider(event.currentTarget.id as OAUTH_PROVIDER_TYPES);

        if (userCredential) {
          if (!userCredential?.user?.emailVerified && isSendEmailVerification)
            await onSendConfirmationEmailHandler({ firebaseUser: userCredential?.user, isDisableNotice: true });

          await acceptAdminInvitationHandler(params?.accessCode);

          await onConnectBackendUser({ user: userCredential?.user });
        }
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Something went wrong, please try again!');
      }
    },
    [params?.accessCode, acceptAdminInvitationHandler, backendUser]
  );

  return {
    onConnectBackendUser,
    acceptAdminInvitationHandler,
    setBackendUser,
    resetAllStepsData,
    toStep,
    onAuthProviderButtonClickHandler,
    onSendConfirmationEmailHandler,
    resetState,
    setData,
    workType,
    invitedProfile,
    backendUser,
    setStateData,
    firebaseUser,
    firebasePhoneAuthData,
  };
};
