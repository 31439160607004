import { Theme } from '@mui/material';
import { ChatOutlined, CallOutlined, CloseOutlined, DoneOutlined } from '@mui/icons-material';
import { TransitionType } from './NotificationCenterApiService';
import { useRouter } from 'features/common';
import { BaseStoreAsyncActionFunction } from 'features/store';
import { ROUTES } from 'features/common/variables/routes';

type Color = (theme: Theme) => string;

const declineColor: Color = ({ palette }) => palette.grey[300];
const caseUpdatesColor: Color = ({ palette }) => palette.info.light;
const callColor: Color = ({ palette }) => palette.success.main;

type ResponseNotificationIconInfo = {
  backgroundColor: Color;
  icon: React.ElementType;
  onClickHandler?: () => Promise<void> | void;
};

type GetNotificationIconInfo = {
  legalCaseId?: string;
  transitionType: TransitionType;
  notificationId: string;
  caseNumber?: string;
  opponentFirebaseId?: string;
  viewedAt: string | null;
  markNotificationAsReadHandler: BaseStoreAsyncActionFunction;
  isAllowTransition: boolean;
};

export const getNotificationIconInfo = ({
  notificationId,
  viewedAt,
  legalCaseId = '',
  caseNumber,
  opponentFirebaseId,
  isAllowTransition,
  transitionType,
  markNotificationAsReadHandler,
}: GetNotificationIconInfo): ResponseNotificationIconInfo => {
  const { navigate, pathname, params } = useRouter();

  const refreshPageHandler = (route: string) => {
    if (pathname.includes(route)) {
      navigate(0);
      return;
    }
  };

  const caseDetailsRedirectHandler = async () => {
    if (!isAllowTransition) {
      !viewedAt && (await markNotificationAsReadHandler(notificationId));
      return;
    }

    const caseId = legalCaseId?.replace('/legal_cases/', '');
    !viewedAt && (await markNotificationAsReadHandler(notificationId));

    caseId === params?.caseId ? refreshPageHandler('/cases/') : navigate(`/cases/${caseId}`);
  };

  const eventsRedirectHandler = async () => {
    if (!isAllowTransition) {
      !viewedAt && (await markNotificationAsReadHandler(notificationId));
      return;
    }

    !viewedAt && (await markNotificationAsReadHandler(notificationId));
    refreshPageHandler(ROUTES.eventCalendar);
    navigate(ROUTES.eventCalendar);
  };

  const chatRedirectHandler = async () => {
    if (!isAllowTransition) {
      !viewedAt && (await markNotificationAsReadHandler(notificationId));
      return;
    }
    !viewedAt && (await markNotificationAsReadHandler(notificationId));

    navigate(ROUTES.legalCaseChat, {
      state: {
        caseNumber,
        userFirebaseId: opponentFirebaseId,
        prevRoute: pathname,
      },
    });
  };

  const onClickItemWithoutTransition = async () => {
    !viewedAt && (await markNotificationAsReadHandler(notificationId));
  };

  switch (transitionType) {
    case 'chat_transition':
      return {
        backgroundColor: ({ palette }) => palette.warning.main,
        icon: ChatOutlined,
        onClickHandler: chatRedirectHandler,
      };
    case 'call_transition':
      return { backgroundColor: callColor, icon: CallOutlined, onClickHandler: eventsRedirectHandler };
    case 'case_transition':
      return { backgroundColor: caseUpdatesColor, icon: DoneOutlined, onClickHandler: caseDetailsRedirectHandler };

    default:
      return { backgroundColor: declineColor, icon: CloseOutlined, onClickHandler: onClickItemWithoutTransition };
  }
};
