import { FC } from 'react';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BaseFormField, notice, ToastType, useAuthentication } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import {
  FirebaseSignInFormSchema,
  firebaseSignInFormValidationSchema,
  FirebaseSignUpFormSchema,
} from 'features/auth/formSchema';
import { createFirebaseService } from 'features/auth/services';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { BasePasswordFormField } from 'features/common/components/BasePasswordFormField';
import { AuthProvidersButtons } from '../AuthProvidersButtons';
import { RedirectButton } from '../RedirectButton';

const { signInWithEmail } = createFirebaseService();

export const FirebaseSignInWithEmail: FC = () => {
  const { onAuthProviderButtonClickHandler, onConnectBackendUser } = useAuthentication();

  const {
    register,
    formState: { isDirty, isSubmitting, isValid, errors },
    handleSubmit,
  } = useForm<FirebaseSignInFormSchema>({
    resolver: yupResolver(firebaseSignInFormValidationSchema),
    mode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  });

  const onFormSubmitHandler = handleSubmit(async (formData: FirebaseSignUpFormSchema) => {
    const { email, password } = formData;

    try {
      const userCredential = await signInWithEmail({ email, password });

      if (userCredential) await onConnectBackendUser({ user: userCredential?.user });
    } catch (error) {
      console.error(error);

      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  });

  const isNextButtonDisabled = !isValid || isSubmitting || !isDirty;

  return (
    <form onSubmit={onFormSubmitHandler}>
      <BaseFormField
        formInputProps={{ ...register('email'), type: 'email', label: 'Email', fullWidth: true }}
        errorValue={errors['email']?.message}
      />

      <BasePasswordFormField
        formInputProps={{
          ...register('password'),
          label: 'Password',
          fullWidth: true,
        }}
        errorValue={errors?.['password']?.message}
      />
      <MDBox display="flex" justifyContent="flex-end">
        <MDTypography component={Link} to={ROUTES.recoveryPassword} variant="button" color="text" fontWeight="medium">
          Forgot your password?
        </MDTypography>
      </MDBox>

      <AuthProvidersButtons
        isSignIn
        isNextButtonDisabled={isNextButtonDisabled}
        isProviderButtonDisabled={isSubmitting}
        onAuthProviderButtonClickHandler={onAuthProviderButtonClickHandler(false)}
      />
      <RedirectButton routeTo={ROUTES.signUp} questionTitle={`Don't have an account?`} buttonText="Sign up" />
    </form>
  );
};
