import React from 'react';
import { useTheme } from '@mui/material';
import {
  DoNotDisturbOnOutlined as AttorneyFollowUpIcon,
  PersonAdd as ClientRegistrationIcon,
  AddCircleOutlineOutlined as LegalCaseCreationIcon,
  EventNote as LegalCaseConsultationCreationIcon,
  Done as LegalCaseConsultationAcceptedIcon,
  Assignment as LegalCaseRepresentationCreationIcon,
  AssignmentTurnedIn as LegalCaseRepresentationAcceptedIcon,
} from '@mui/icons-material';

import MDBox from 'components/MDBox';

import { AdminNotificationType } from './AdminDashboardApiService';

export const notificationIcons: Record<AdminNotificationType, JSX.Element> = {
  attorney_follow_up: <AttorneyFollowUpIcon />,
  client_registration: <ClientRegistrationIcon />,
  legal_case_creation: <LegalCaseCreationIcon />,
  legal_case_consultation_creation: <LegalCaseConsultationCreationIcon />,
  legal_case_consultation_accepted: <LegalCaseConsultationAcceptedIcon />,
  legal_case_representation_creation: <LegalCaseRepresentationCreationIcon />,
  legal_case_representation_accepted: <LegalCaseRepresentationAcceptedIcon />,
};

interface NotificationIconProps {
  type: AdminNotificationType;
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({ type }) => {
  const theme = useTheme();

  const iconData = (() => {
    switch (type) {
      case 'attorney_follow_up':
        return { backgroundColor: theme.palette.warning.main, icon: AttorneyFollowUpIcon };
      case 'client_registration':
        return { backgroundColor: theme.palette.success.main, icon: ClientRegistrationIcon };
      case 'legal_case_creation':
        return { backgroundColor: theme.palette.info.main, icon: LegalCaseCreationIcon };
      case 'legal_case_consultation_creation':
        return { backgroundColor: theme.palette.primary.main, icon: LegalCaseConsultationCreationIcon };
      case 'legal_case_consultation_accepted':
        return { backgroundColor: theme.palette.success.main, icon: LegalCaseConsultationAcceptedIcon };
      case 'legal_case_representation_creation':
        return { backgroundColor: theme.palette.secondary.main, icon: LegalCaseRepresentationCreationIcon };
      case 'legal_case_representation_accepted':
        return { backgroundColor: theme.palette.success.main, icon: LegalCaseRepresentationAcceptedIcon };
      default:
        return { backgroundColor: theme.palette.grey[400], icon: null };
    }
  })();

  if (!iconData.icon) return null;

  const IconComponent = iconData.icon;

  return (
    <MDBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: iconData.backgroundColor,
        width: 25,
        height: 25,
        p: '4px',
      }}
    >
      <IconComponent fontSize="small" sx={{ color: 'white !important' }} />
    </MDBox>
  );
};
