import { ROUTES } from 'features/common/variables/routes';

export const getPath = (searchParams: URLSearchParams, isAuthorized: boolean) => {
  const caseId = searchParams.get('caseId');
  const eventId = searchParams.get('eventId');

  if (isAuthorized) {
    if (caseId) return `/cases/${caseId}`;

    if (eventId) return ROUTES.eventCalendar;

    return ROUTES.home;
  } else {
    return ROUTES.signIn;
  }
};
