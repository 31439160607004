import { FC, memo, useEffect, useState } from 'react';
import { Card, CardContent, CircularProgress, Grid } from '@mui/material';
import { SideMenu, ToastType, notice, useBoolean, useUser } from 'features/common';
import { useMyCompany } from 'features/store';
import { ViewAllOffersButton } from 'features/case-details/components/CaseInfo';
import MDTypography from 'components/MDTypography';
import { subscriptionsApiService } from 'features/subscriptions/services/subscriptionsApiService';
import { CompanyInvitationItem } from './CompanyInvitationItem';
import { AllInvitationsOffersMenuContent } from './AllInvitationsOffersMenuContent';

export const CompanyInvitations: FC = memo(() => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    approveInvitationHandler,
    declineInvitationHandler,
    fetchMoreOffersHandler,
    getCompanyInvitations,
    invitations,
    invitationsTotalItems,
  } = useMyCompany(state => ({
    getCompanyInvitations: state.getCompanyInvitationsHandler,
    invitations: state.companyInvitationData?.invitations,
    invitationsTotalItems: state.companyInvitationData?.totalItems,
    declineInvitationHandler: state.declineInvitationHandler,
    fetchMoreOffersHandler: state.getMoreInvitationsHandler,
    approveInvitationHandler: state.approveInvitationHandler,
  }));

  const onGetMineCompanyInvitationsHandler = async () => {
    setIsLoading(true);
    await getCompanyInvitations({ page: 1, itemsPerPage: 4 });
    setIsLoading(false);
  };

  useEffect(() => {
    onGetMineCompanyInvitationsHandler();
  }, []);

  const isViewAllOffersButton = invitationsTotalItems > 4;

  const [isViewAllMenuOpen, openViewAllMenu, closeViewAllMenu] = useBoolean(false);

  const [isOffersLoading, setIsOffersLoading] = useState(false);

  const onViewAllButtonHandler = async () => {
    setIsOffersLoading(true);
    openViewAllMenu();
    await getCompanyInvitations({ page: 1, itemsPerPage: 6 });
    setIsOffersLoading(false);
  };

  const onClickDeclineInvitationButtonHandler = async (id: string) => {
    await declineInvitationHandler(id);
    invitationsTotalItems === 1 && closeViewAllMenu();
  };

  const { isNavigationBlocked, setStateData, connectBackendUserHandler } = useUser();

  const { checkSubscriptionStatus } = subscriptionsApiService();

  const onClickApproveInvitationHandler = async (id: string) => {
    await approveInvitationHandler(id);
    await connectBackendUserHandler();
    if (isNavigationBlocked) {
      try {
        const { data } = await checkSubscriptionStatus();
        data.hasSubscription && setStateData('isNavigationBlocked', false);
      } catch (error) {
        console.error(error);
        notice(ToastType.ERROR, 'Failed to get user subscription status!');
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Grid item xs={12}>
          <Card>
            <CardContent sx={{ justifyContent: 'center', width: 1, pt: 3, display: 'flex', alignItems: 'center' }}>
              <CircularProgress />
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card sx={{ width: 1 }}>
            <CardContent sx={{ p: '24px !important' }}>
              {!invitations?.length && (
                <MDTypography sx={{ textAlign: 'center' }}>No invitations were found</MDTypography>
              )}

              {invitations?.length ? (
                <>
                  <MDTypography sx={{ textAlign: 'center', fontWeight: 500, mb: 0.5 }}>Invitations</MDTypography>
                  <Grid container spacing={2}>
                    {invitations?.slice(0, isViewAllOffersButton ? 3 : 4).map(invitation => (
                      <Grid
                        key={invitation['@id']}
                        item
                        xs={12}
                        sm={12}
                        md={invitations?.length === 1 ? 4 : 6}
                        lg={invitations?.length === 1 ? 4 : isViewAllOffersButton ? 3.2 : 3}
                      >
                        <CompanyInvitationItem
                          invitorFirstName={invitation?.inviter?.userProfile?.firstName}
                          invitorLastName={invitation?.inviter?.userProfile?.lastName}
                          invitorLogoURL={invitation?.inviter?.logo?.publicUrl}
                          companyFullName={invitation?.company?.fullName}
                          approveOfferHandler={onClickApproveInvitationHandler}
                          declineOfferHandler={declineInvitationHandler}
                          offerId={invitation?.['@id']}
                        />
                      </Grid>
                    ))}
                    {isViewAllOffersButton && (
                      <ViewAllOffersButton onClickViewAllButtonHandler={onViewAllButtonHandler} />
                    )}
                  </Grid>
                </>
              ) : null}
            </CardContent>
          </Card>

          <SideMenu
            isOpen={isViewAllMenuOpen}
            onClose={closeViewAllMenu}
            title="All invitation offers"
            customWidth={450}
          >
            <AllInvitationsOffersMenuContent
              fetchMoreOffersHandler={fetchMoreOffersHandler}
              approveOfferHandler={onClickApproveInvitationHandler}
              declineOfferHandler={onClickDeclineInvitationButtonHandler}
              isLoading={isOffersLoading}
              invitationOffers={invitations}
              totalItems={invitationsTotalItems}
            />
          </SideMenu>
        </Grid>
      )}
    </>
  );
});
