import { FC } from 'react';
import { MyLegalCasesTemplate } from 'features/my-legal-cases';
import { MyClientsTemplate } from 'features/my-clients';
import { useUser } from 'features/common';

export const CasesTemplate: FC = () => {
  const isAttorney = useUser().isAttorney;

  return isAttorney ? <MyClientsTemplate /> : <MyLegalCasesTemplate />;
};
