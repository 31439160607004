import { BaseData, backendApiClient } from '../index';

const BASE_API_URL: Readonly<string> = '/app/statistics';

type StatisticValue = number | null;

export type GetAppStatisticResponse = BaseData<'StatisticDto'> &
  Partial<{
    companiesWaitingForApproval: StatisticValue;
    caseLawTypeWaitingForSuggestion: StatisticValue;
    reviewsWaitingForApproval: StatisticValue;
    closedLegalCaseCount: StatisticValue;
    newUserFeedCount: StatisticValue;
    openLegalCaseCount: StatisticValue;
    clientsCount: StatisticValue;
  }>;

export type UserStatistic = Omit<
  GetAppStatisticResponse,
  'reviewsWaitingForApproval' | 'companiesWaitingForApproval' | 'caseLawTypeWaitingForSuggestion'
> & {
  newChatMessageCount: 0;
  applicationBadge: 0;
  invitationCasesCount: 0;
};

export type AdminStatistic = Pick<
  GetAppStatisticResponse,
  'reviewsWaitingForApproval' | 'companiesWaitingForApproval' | 'caseLawTypeWaitingForSuggestion'
> & {
  feedNotViewedCount: StatisticValue;
};

export const appStatisticApiService = () => {
  const getAppStatistic = async () => await backendApiClient({}).get<GetAppStatisticResponse>(BASE_API_URL);

  const getUserStatistic = async (firebaseUserId: string) =>
    await backendApiClient({}).get<UserStatistic>(`/user_statistics/${firebaseUserId}`);

  const getAdminStatistic = async () => await backendApiClient({}).get<AdminStatistic>('/security/admin_statistics');

  return {
    getAppStatistic,
    getUserStatistic,
    getAdminStatistic,
  };
};
