import { useCallback, useEffect, useState } from 'react';
import {
  Representation,
  legalCaseRepresentationApiService,
} from 'features/common/services/legalCaseRepresentationApiService';
import { useMercure } from 'features/common/hooks/useMercure';
import { BaseData, MercureLinkMap, ToastType, notice, useBoolean } from 'features/common';
import { caseDetailsApiService } from 'features/case-details/CaseDetailsApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { AudioVideoRoomProps } from '../types';

type RepresentationOwner = BaseData<'User'> & {
  firebaseUser: string;
};

export type MercureLegalCaseRepresentation = Representation & { owner: RepresentationOwner };

type EventMessage = BaseData<'AgoraEvent'> & {
  '@context': string;
  id: string;
  awaitingSignByAttorney: number;
  awaitingSignByClient: number;
  legalCaseRepresentation: MercureLegalCaseRepresentation;
};

export type RepresentationStatus = 'approve' | 'pending' | 'declined';
export type VideoCallRepresentation = {
  status: 'approve' | 'pending' | 'declined';
  representation: Representation;
};

type UseRepresentationProps = Pick<
  AudioVideoRoomProps,
  | 'legalCaseId'
  | 'eventId'
  | 'isAttorney'
  | 'isCaseOnConsultation'
  | 'setRepresentationId'
  | 'updateEventdata'
  | 'eventType'
>;

type Reason = 'decline' | 'accept' | 'send';

export const useRepresentation = ({
  isCaseOnConsultation,
  eventId,
  legalCaseId,
  isAttorney,
  eventType,
  setRepresentationId,
  updateEventdata,
}: UseRepresentationProps) => {
  const { createRepresentation, approveRepresentation, deleteRepresentation } = legalCaseRepresentationApiService();
  const { getLegalCaseRepresentations, getLegalCaseFilesRequiredToSign } = caseDetailsApiService();

  const legalCaseUID = legalCaseId?.replace('/legal_cases/', '');

  const [isDialogOpen, openDialogWindow, closeDialogWindow] = useBoolean(false);
  const [isSendingOffer, setIsSendingOffer] = useState(false);
  const [reason, setReason] = useState<null | Reason>(null);

  const [awaitingFilsToSign, setAwaitingFilsToSign] = useState({ awaitingSignByAttorney: 0, awaitingSignByClient: 0 });

  const [representation, setRepresentation] = useState<VideoCallRepresentation | null>(null);

  const onClickSendRepresentationHandler = () => {
    setReason('send');
    openDialogWindow();
  };

  const onClickApproveRepresentationHandler = () => {
    setReason('accept');
    openDialogWindow();
  };

  const onClickDeclineRepresentationHandler = () => {
    setReason('decline');
    openDialogWindow();
  };

  const onGetFilesToSignHandler = async () => {
    try {
      const { data } = await getLegalCaseFilesRequiredToSign(legalCaseUID);

      if (data?.files.length) {
        setAwaitingFilsToSign(prevState => ({
          ...prevState,
          [isAttorney ? 'awaitingSignByAttorney' : 'awaitingSignByClient']: data?.files.length,
        }));
      }
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Failed to fetch files, try again!');
    }
  };

  const onGetRepresentationHandler = async () => {
    try {
      const { data } = await getLegalCaseRepresentations(legalCaseUID, {
        page: 1,
        itemsPerPage: 1,
      });
      const represenation = data['hydra:member']?.[0];

      if (represenation) {
        setRepresentation({ representation: represenation, status: 'pending' });
        setRepresentationId(represenation?.id);
      }
    } catch (error) {
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  };

  const onSendRepresentationOfferButtonClickHandler = useCallback(async () => {
    try {
      setIsSendingOffer(true);
      closeDialogWindow();
      await createRepresentation(legalCaseId, eventType === 'video_call' ? 'video' : 'audio');

      setIsSendingOffer(false);
      notice(ToastType.SUCCESS, 'Successfully sent offer!');
    } catch (error) {
      setIsSendingOffer(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  }, [legalCaseId, eventType]);

  const onReceiveEventMessageHandler = useCallback(async (event: MessageEvent) => {
    const message: EventMessage = JSON.parse(event.data);

    if (message?.legalCaseRepresentation) {
      if (message?.legalCaseRepresentation?.status === 'pending') {
        setRepresentation({ representation: message?.legalCaseRepresentation, status: 'pending' });
        setRepresentationId(message?.legalCaseRepresentation?.id);
      }
      if (message?.legalCaseRepresentation?.status === 'approve') {
        setRepresentation({ representation: message?.legalCaseRepresentation, status: 'approve' });
        setRepresentationId('');
        updateEventdata();
      }
    } else {
      setRepresentation(null);
      setRepresentationId('');
    }

    setAwaitingFilsToSign(prevState => ({
      ...prevState,
      awaitingSignByAttorney: message?.awaitingSignByAttorney,
      awaitingSignByClient: message?.awaitingSignByClient,
    }));
  }, []);

  const [isRejecting, setIsRejecting] = useState(false);
  const [isApprovingRepresentation, setIsApprovingRepresentation] = useState(false);

  const onAcceptRepresentationOfferHandler = useCallback(async () => {
    try {
      setIsApprovingRepresentation(true);
      closeDialogWindow();
      await approveRepresentation(representation?.representation?.id);
      setRepresentationId('');
      setIsApprovingRepresentation(false);
    } catch (error) {
      setIsApprovingRepresentation(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  }, [representation]);

  const onDeclineRepresentationOfferHandler = useCallback(async () => {
    try {
      setIsRejecting(true);
      closeDialogWindow();
      await deleteRepresentation?.(representation?.representation?.id);
      setRepresentationId('');
      setIsRejecting(false);
    } catch (error) {
      setIsRejecting(false);
      paymentRequiredErrorHandler(error);
      console.error(error);
    }
  }, [representation]);

  useMercure({
    topicSubURL: 'agora_events',
    mercureSubscribeURL: MercureLinkMap[process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3000'],
    eventId: eventId,
    onReceiveEventHandler: onReceiveEventMessageHandler,
  });

  useEffect(() => {
    legalCaseId && isCaseOnConsultation && onGetRepresentationHandler();
    legalCaseId && !isCaseOnConsultation && onGetFilesToSignHandler();
  }, [legalCaseId]);

  const [isSignDocumentsModalOpen, openSignDocumentsModal, closeSignDocumentsModal] = useBoolean(false);

  const dialogWindowAcceptHandler =
    reason === 'send'
      ? onSendRepresentationOfferButtonClickHandler
      : reason === 'accept'
      ? onAcceptRepresentationOfferHandler
      : onDeclineRepresentationOfferHandler;

  return {
    isRejecting,
    isApprovingRepresentation,
    isSendingOffer,
    representation,
    legalCaseUID,
    awaitingFilsToSign,
    isSignDocumentsModalOpen,
    openSignDocumentsModal,
    closeSignDocumentsModal,
    onSendRepresentationOfferButtonClickHandler,
    onDeclineRepresentationOfferHandler,
    onAcceptRepresentationOfferHandler,
    isDialogOpen,
    openDialogWindow,
    closeDialogWindow,
    onClickDeclineRepresentationHandler,
    onClickApproveRepresentationHandler,
    onClickSendRepresentationHandler,
    dialogWindowAcceptHandler,
  };
};
