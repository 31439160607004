import { FC, memo } from 'react';
import { PersonOutline, PhoneOutlined, VideocamOutlined, KeyboardBackspaceOutlined } from '@mui/icons-material';
import { Card, CardContent, Stack, Tooltip } from '@mui/material';
import { getAvatarCharacters } from 'features/common/helpers/utilities';
import MDAvatar from 'components/MDAvatar';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { SideMenu, useBoolean, useRouter, useUser } from 'features/common';
import { useAudioVideoChat } from 'features/store';
import { AgoraEventType } from 'features/common/services/agoraEventApiService';
import { ROUTES } from 'features/common/variables/routes';
import { useUserProfile } from 'features/common/hooks/useUserProfile';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { UserOverviewContent } from 'features/case-details/components/CaseInfo/UserOverviewContent';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { CompanyInfoSideMenuContent } from 'features/my-company/components/CompanyInfoSideMenuContent';

type ChatHeaderProps = {
  logoURL?: string;
  firstName: string;
  lastName: string;
  onClickSendRepresentationButtonHandler: () => Promise<void>;
  isSendRepresentationRequestLoading: boolean;
  isCaseOnCounsultation: boolean;
  isRepresentationExist: boolean;
  isHideBackButton?: boolean;
  caseId: string;
  isAttorney: boolean;
  endParticipantFirebaseId: string;
  isChatActive: boolean;
};

export const ChatHeader: FC<ChatHeaderProps> = memo(
  ({
    isHideBackButton,
    logoURL,
    firstName,
    lastName,
    onClickSendRepresentationButtonHandler,
    isSendRepresentationRequestLoading,
    isCaseOnCounsultation,
    isRepresentationExist,
    isAttorney,
    caseId,
    endParticipantFirebaseId,
    isChatActive,
  }) => {
    const { back, navigate, pathname } = useRouter();
    const setStartCallProps = useAudioVideoChat(state => state.setStartCallProps);

    const onAudioVideoButtonClickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
      const eventType = event.currentTarget.id as AgoraEventType;

      setStartCallProps({
        caseId,
        eventType,
        endParticipantFirebaseId,
      });
      navigate(eventType === 'voice_call' ? ROUTES.audioRoom : ROUTES.videoRoom, {
        state: {
          prevRoute: pathname,
        },
      });
    };

    const {
      isUserOverviewMenuOpen,
      isUserProfileOverviewLoading,
      onCloseUserOverviewHandler,
      onUserAvatarClickHandler,
      userRatings,
      userProfileOverview,
      isOpenCompanyInfoMenu,
      onClickViewCompanyProfileButtonHandler,
      onCloseCompanyInfoMenuHandler,
    } = useUserProfile();

    const isFullName = !firstName && !lastName;

    const [
      isSendRepresentationDialogOpen,
      openSendRepresentationOfferDialogHandler,
      closeSendRepresentationOfferDialogHandler,
    ] = useBoolean(false);

    const onApproveSendOffer = async () => {
      closeSendRepresentationOfferDialogHandler();
      onClickSendRepresentationButtonHandler();
    };

    // TODO conference hack
    const { isCanSendRepresentationRequest } = useUser();

    return (
      <Card>
        <CardContent sx={{ p: 2 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1.5} alignItems="center">
              {!isHideBackButton && (
                <MDButton
                  iconOnly
                  onClick={back}
                  size="medium"
                  sx={{
                    '& svg': {
                      fontSize: '25px !important',
                    },
                  }}
                >
                  <KeyboardBackspaceOutlined />
                </MDButton>
              )}
              <MDAvatar
                src={logoURL || ''}
                size="lg"
                alt="user avatar"
                bgColor="info"
                id={endParticipantFirebaseId}
                onClick={isFullName ? onUserAvatarClickHandler : undefined}
                sx={{
                  '& img': {
                    height: 1,
                  },
                  fontSize: 16,
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                {isFullName ? getAvatarCharacters(firstName, lastName) : <PersonOutline fontSize="medium" />}
              </MDAvatar>
              <MDTypography variant="subtitle1" fontWeight="medium" fontSize={18}>
                {`${firstName} ${lastName}`}
              </MDTypography>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="center"
              sx={{
                '& button > svg': {
                  fontSize: '24px !important',
                },
              }}
            >
              {/* TODO conrefence hack */}
              {isAttorney &&
                isCaseOnCounsultation &&
                !isRepresentationExist &&
                isCanSendRepresentationRequest &&
                isChatActive && (
                  <MDButton
                    color="info"
                    variant="contained"
                    size="large"
                    sx={{ textTransform: 'capitalize', px: '14px' }}
                    onClick={openSendRepresentationOfferDialogHandler}
                    isLoading={isSendRepresentationRequestLoading}
                    disabled={isSendRepresentationRequestLoading}
                  >
                    Send representation request
                  </MDButton>
                )}
              {/* TODO conrefence hack */}
              {isAttorney && isCaseOnCounsultation && !isRepresentationExist && !isCanSendRepresentationRequest && (
                <Tooltip title="Free request was used">
                  <MDButton
                    color="info"
                    variant="contained"
                    size="large"
                    sx={{ textTransform: 'capitalize', px: '14px' }}
                    onClick={() => navigate(ROUTES.subscription)}
                  >
                    Subscribe
                  </MDButton>
                </Tooltip>
              )}
              <MDButton color="info" iconOnly id="voice_call" onClick={onAudioVideoButtonClickHandler}>
                <PhoneOutlined fontSize="medium" />
              </MDButton>
              <MDButton color="info" iconOnly id="video_call" onClick={onAudioVideoButtonClickHandler}>
                <VideocamOutlined fontSize="medium" />
              </MDButton>
            </Stack>
          </Stack>
        </CardContent>

        <MenuPopover open={isUserOverviewMenuOpen} onClose={onCloseUserOverviewHandler} arrow="top-left">
          <UserOverviewContent
            attorneyDescription={!isAttorney ? userProfileOverview?.attorneyProfile?.description : ''}
            lawTypes={!isAttorney ? userProfileOverview?.attorneyProfile?.caseLawTypes : null}
            country={userProfileOverview?.userProfile?.country?.name}
            state={userProfileOverview?.userProfile?.state?.name}
            isLoading={isUserProfileOverviewLoading}
            userRatings={!isAttorney ? userRatings : null}
            companyInfo={{
              companyFullName: userProfileOverview?.company?.fullName,
              companyLogo: userProfileOverview?.company?.logo?.publicUrl,
            }}
            onClickViewCompanyProfileButtonHandler={onClickViewCompanyProfileButtonHandler}
          />
        </MenuPopover>

        <SideMenu
          customWidth={450}
          isOpen={isOpenCompanyInfoMenu}
          onClose={onCloseCompanyInfoMenuHandler}
          title="Company profile"
        >
          <CompanyInfoSideMenuContent companyId={userProfileOverview?.company?.['@id']} />
        </SideMenu>

        <DialogWindow
          onCloseDialogHandler={closeSendRepresentationOfferDialogHandler}
          onApproveDialogHandler={onApproveSendOffer}
          isDialogOpen={isSendRepresentationDialogOpen}
          dialogTitle="Are you sure you want to send representation offer?"
        />
      </Card>
    );
  }
);
