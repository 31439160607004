import { FC, memo, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { CheckRounded, MoreVert } from '@mui/icons-material';
import { Card, CardContent, CircularProgress, IconButton, ListItemIcon, MenuItem, Stack } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { usePopover } from 'features/common/hooks/usePopover';
import { MenuPopover } from 'features/common/components/MenuPopover';
import { AdminNotificationType } from './AdminDashboardApiService';
import { NotificationIcon } from './config';

type NotificationItemProps = {
  message: string;
  createdAt: string;
  isUnread: boolean;
  onReadNotifcationHandler: (id: string) => Promise<void>;
  id: string;
  notificationType: AdminNotificationType;
  lastElementRef?: React.MutableRefObject<HTMLDivElement>;
};

export const NotificationItem: FC<NotificationItemProps> = memo(
  ({ id, message, createdAt, isUnread, onReadNotifcationHandler, notificationType, lastElementRef }) => {
    const distanceToNow = formatDistanceToNow(new Date(createdAt), { addSuffix: true });

    const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

    const [isLoading, setIsLoading] = useState(false);

    const onClickReadNotifcationHandler = async () => {
      setIsLoading(true);
      await onReadNotifcationHandler(id);
      setIsLoading(false);
      handleClosePopover();
    };

    return (
      <Card ref={lastElementRef} sx={{ height: 1 }}>
        <CardContent sx={{ p: 1.5, height: 1 }}>
          <Stack width={1} height={1}>
            {isUnread && (
              <Stack direction="row" justifyContent="flex-end">
                <MDBox
                  component="span"
                  sx={{
                    display: 'flex',
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: ({ palette }) => palette.info.light,
                  }}
                />
              </Stack>
            )}
            <Stack direction="row" spacing={1} alignItems="flex-start" width={1} flex={1}>
              <Stack spacing={1.5} direction="row" width={1} alignItems="flex-start">
                {NotificationIcon({ type: notificationType })}
                <Stack component="ul">
                  <Stack component="li" whiteSpace="pre-line">
                    <MDTypography
                      variant="body2"
                      fontSize={14}
                      maxWidth={545}
                      sx={{ wordBreak: 'break-word', whiteSpace: 'break-spaces' }}
                      overflow="hidden"
                      fontWeight={400}
                    >
                      {message}
                    </MDTypography>
                  </Stack>
                </Stack>
              </Stack>

              {isUnread && (
                <IconButton onClick={handleOpenPopover}>
                  <MoreVert fontSize="medium" />
                </IconButton>
              )}
            </Stack>

            <MDTypography mt={1} variant="button">
              {distanceToNow}
            </MDTypography>
          </Stack>
        </CardContent>

        <MenuPopover open={openPopover} onClose={handleClosePopover}>
          <MenuItem sx={{ px: 1 }} onClick={onClickReadNotifcationHandler}>
            <ListItemIcon sx={{ minWidth: '32px !important' }}>
              {isLoading ? <CircularProgress size={20} /> : <CheckRounded fontSize="medium" />}
            </ListItemIcon>
            <MDTypography variant="body2">Mark as read</MDTypography>
          </MenuItem>
        </MenuPopover>
      </Card>
    );
  }
);
