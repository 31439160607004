import axios from 'axios';
import * as process from 'process';
import { firebaseAuth } from 'features/auth/firebase';
import { ApiInstance } from '../index';

export const { REACT_APP_BASE_API_URL } = process.env;

export let controller = new AbortController();

export const resetAbortContoller = () => {
  controller = new AbortController();
};

export const axiosInstance = axios.create({
  baseURL: REACT_APP_BASE_API_URL,
  timeout: 50_000,
  signal: controller.signal,
});

axiosInstance.interceptors.request.use(
  async config => {
    const token = await firebaseAuth?.currentUser?.getIdToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Accept = 'application/ld+json';
    }
    return config;
  },

  error => {
    return Promise.reject(error);
  }
);

export const backendApiClient: ApiInstance = ({ headers = {}, responseType = 'json' }) => {
  axiosInstance.defaults.headers.common = {
    ...headers,
    'Content-Type': headers?.['Content-Type'] ? headers?.['Content-Type'] : 'application/ld+json',
  };

  axiosInstance.defaults.responseType = responseType;
  axiosInstance.defaults.signal = controller.signal;
  return axiosInstance;
};
