import { FC, useEffect } from 'react';
import { Badge, Card, CardContent, Grid, Stack } from '@mui/material';
import { CheckRounded, FilterListOutlined, Search } from '@mui/icons-material';
import { BaseFormField, DashboardLayout, SideMenu } from 'features/common';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { FetchMoreLoader, Loader } from 'features/common/components/Loader';
import { useAdminDashboardTemplateState } from './useAdminDashboardTemplate.state';
import { NotificationItem } from './NotificationItem';
import { FiltersMenuContent } from './FIltersMenuContent';

export const AdminDashboardTemplate: FC = () => {
  const {
    isLoading,
    isReadAllLoading,
    notifications,
    onClickReadAllNotifcationsHandler,
    onClickReadNotifcationHandler,
    onGetNotificationsHandler,
    isFiltersMenuOpen,
    onOpenFiltersMenuHandler,
    onCloseFiltersMenuHandler,
    filtersData,
    searchParams,
    lastElementRef,
    isFetchMoreLoading,
    onApplyFiltersHandler,
    filtersCount,
    debounceValue,
    onChangeSearchInputHandler,
    searchValue,
  } = useAdminDashboardTemplateState();

  useEffect(() => {
    onGetNotificationsHandler(searchParams);
  }, [filtersData, debounceValue]);

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ mb: 2 }}>
            <CardContent sx={{ pb: '16px !important' }}>
              <Stack spacing={1.5}>
                <MDTypography fontWeight={500}>Notifications</MDTypography>
                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems="center"
                  justifyContent={{ xs: 'center', sm: 'space-between' }}
                  spacing={2}
                >
                  <BaseFormField
                    formFieldProps={{
                      pb: 0,
                    }}
                    formInputProps={{
                      label: 'Search',
                      placeholder: 'Find the notications',
                      width: '100%',
                      onChange: onChangeSearchInputHandler,
                      value: searchValue,
                      InputProps: {
                        startAdornment: <Search fontSize="medium" />,
                      },
                    }}
                  />
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent={{ xs: 'center', sm: 'flex-end' }}
                    spacing={2}
                  >
                    <MDButton
                      onClick={onClickReadAllNotifcationsHandler}
                      color="info"
                      size="small"
                      startIcon={isReadAllLoading ? null : <CheckRounded fontSize="medium" />}
                      variant="outlined"
                      isLoading={isReadAllLoading}
                      disabled={isReadAllLoading}
                    >
                      Mark all as read
                    </MDButton>

                    <MDButton
                      disableRipple
                      variant="contained"
                      size="small"
                      color="info"
                      onClick={onOpenFiltersMenuHandler}
                      endIcon={
                        <Badge badgeContent={filtersCount} color="error" sx={{ '.MuiBadge-badge': { right: '-5px' } }}>
                          <FilterListOutlined fontSize="medium" />
                        </Badge>
                      }
                    >
                      Filters
                    </MDButton>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {isLoading && <Loader />}
      {!isLoading && !notifications.length && (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <MDTypography>No notifications were found...</MDTypography>
        </Stack>
      )}
      {!isLoading && (
        <>
          <Grid container spacing={2}>
            {notifications.map((el, index) => (
              <Grid key={el.id} item xs={12} md={6}>
                <NotificationItem
                  lastElementRef={index === notifications.length - 1 ? lastElementRef : null}
                  id={el.id}
                  onReadNotifcationHandler={onClickReadNotifcationHandler}
                  message={el?.message}
                  createdAt={el?.createdAt}
                  isUnread={!el?.viewedAt}
                  notificationType={el.type}
                />
              </Grid>
            ))}
          </Grid>
          {isFetchMoreLoading && <FetchMoreLoader />}
        </>
      )}

      <SideMenu isOpen={isFiltersMenuOpen} onClose={onCloseFiltersMenuHandler} title="Filters" customWidth={400}>
        <FiltersMenuContent filtersData={filtersData} onApplyFilters={onApplyFiltersHandler} />
      </SideMenu>
    </DashboardLayout>
  );
};
