import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ToastType, notice, useBoolean, useDebounce, useIsOnScreen } from 'features/common';
import { GetAdminNotificationsParams, ITEMS_PER_PAGE, adminDashboardApiService } from './AdminDashboardApiService';
import { AdminNotificationsFiltersData, useAdminDashboard } from 'features/store';
import { abortRequestErrorHandler } from 'features/common/errorHanders';

const { getAdminNotifications, readAllAdminNotifications, viewAdminNotification } = adminDashboardApiService();

export const useAdminDashboardTemplateState = () => {
  const {
    notifications: { data: notifications, totalItems, filtersData },
    setNotifications,
    setFiltersData,
    setMoreNotifications,
  } = useAdminDashboard();

  const filtersCount = useMemo(
    () =>
      Object.values(filtersData).reduce((acc, el) => {
        if (!Array.isArray(el) && el) {
          acc += 1;
        } else {
          acc += el?.length || 0;
        }

        return acc;
      }, 0),
    [filtersData]
  );

  const [isLoading, setIsLoading] = useState(true);

  const onGetNotificationsHandler = async (params?: GetAdminNotificationsParams) => {
    try {
      !isLoading && setIsLoading(true);
      const { data } = await getAdminNotifications(params);
      setNotifications({ data: data['hydra:member'], totalItems: data['hydra:totalItems'] });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      abortRequestErrorHandler(error, 'Failed to get notifications, please try again!');
    }
  };

  const onClickReadNotifcationHandler = useCallback(async (id: string) => {
    try {
      await viewAdminNotification(id);
      await onGetNotificationsHandler();

      notice(ToastType.SUCCESS, 'Successfully read notification!');
    } catch (error) {
      abortRequestErrorHandler(error, 'Failed to read notification, please try again!');
    }
  }, []);

  const [isReadAllLoading, setIsReadAllLoading] = useState(false);

  const onClickReadAllNotifcationsHandler = async () => {
    try {
      setIsReadAllLoading(true);
      await readAllAdminNotifications();
      await onGetNotificationsHandler({ ...searchParams, page: 1 });
      setCurrentPage(1);

      notice(ToastType.SUCCESS, 'Successfully read all notifications!');
    } catch (error) {
      console.error(error);
      abortRequestErrorHandler(error, 'Failed to read notifications, please try again!');
    } finally {
      setIsReadAllLoading(false);
    }
  };

  // infinity loader
  const [currentPage, setCurrentPage] = useState(1);
  const pages: Readonly<number> = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const lastElementRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(isLoading || isFetchMoreLoading ? null : lastElementRef);

  const onFetchMoreCaseOffersHandler = async () => {
    try {
      setIsFetchMoreLoading(true);

      const nextPage = currentPage + 1;
      const { data } = await getAdminNotifications({ ...searchParams, page: nextPage });
      setMoreNotifications({ data: data['hydra:member'] });

      setCurrentPage(nextPage);
    } catch (error) {
      abortRequestErrorHandler(error, 'Failed to get notifications, please try again!');
    } finally {
      setIsFetchMoreLoading(false);
    }
  };

  useEffect(() => {
    if (isOnScreen && currentPage < pages) onFetchMoreCaseOffersHandler();
  }, [isOnScreen]);

  // filters
  const [searchValue, setSearchValue] = useState('');

  const onChangeSearchInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchValue(value);

    if ((!value || value) && currentPage > 1) setCurrentPage(1);
  };

  const debounceValue = useDebounce(searchValue);

  const [isFiltersMenuOpen, onOpenFiltersMenuHandler, onCloseFiltersMenuHandler] = useBoolean(false);

  const searchParams: GetAdminNotificationsParams = {
    ...(filtersData['createdAt[strictly_after]'] && {
      'createdAt[strictly_after]': filtersData['createdAt[strictly_after]'],
    }),
    ...(filtersData['createdAt[strictly_before]'] && {
      'createdAt[strictly_before]': filtersData['createdAt[strictly_before]'],
    }),
    ...(filtersData['type[]'].length && {
      'type[]': filtersData['type[]'].map(el => el.id),
    }),
    ...(debounceValue && {
      message: debounceValue,
    }),
    page: currentPage,
    itemsPerPage: ITEMS_PER_PAGE,
  };

  const onApplyFiltersHandler = (params: AdminNotificationsFiltersData) => {
    setFiltersData(params);
    setCurrentPage(1);
    onCloseFiltersMenuHandler();
  };

  return {
    onGetNotificationsHandler,
    onClickReadNotifcationHandler,
    onClickReadAllNotifcationsHandler,
    isLoading,
    notifications,
    isReadAllLoading,
    isFetchMoreLoading,
    isFiltersMenuOpen,
    onOpenFiltersMenuHandler,
    onCloseFiltersMenuHandler,
    filtersData,
    searchParams,
    lastElementRef,
    onApplyFiltersHandler,
    filtersCount,
    onChangeSearchInputHandler,
    searchValue,
    debounceValue,
  };
};
