import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AgoraAudioVideoIncomingCallData } from 'features/common/services/agoraEventApiService';
import { ROUTES } from 'features/common/variables/routes';
import { useRouter, useUser } from 'features/common';
import { useLockScreen, useNotificationsCenter } from 'features/store';
import { shallow } from 'zustand/shallow';

function paramsToObject(entries: IterableIterator<[string, string]>) {
  const result: { [key: string]: string } = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export const IncominCallPush = (): null => {
  const [searchParams, _] = useSearchParams();

  const navigate = useRouter().push;

  const { pushToken, setCallInvitationMessage, setIsCallModalOpen } = useNotificationsCenter(state => ({
    setCallInvitationMessage: state.setCallInvitationMessage,
    setIsCallModalOpen: state.setIsCallModalOpen,
    pushToken: state.pushNotificationData?.firebaseToken,
  }));

  const { isPinCodeActive } = useUser();

  const { onIdleHandler, isRedirectToLockScreen } = useLockScreen(
    state => ({
      isRedirectToLockScreen: state.isRedirectToLockScreen,
      onIdleHandler: state.onIdleHandler,
    }),
    shallow
  );

  useEffect(() => {
    if (!searchParams.toString().length || !searchParams.has('agoraEvent')) return;

    const callData = paramsToObject(searchParams.entries());

    setCallInvitationMessage(callData as AgoraAudioVideoIncomingCallData);

    if (isPinCodeActive) {
      if (!isRedirectToLockScreen) {
        onIdleHandler(false, pushToken);
      } else {
        navigate(ROUTES.lockScreen).then(() => setIsCallModalOpen(true));
      }
    } else {
      navigate(ROUTES.myLegalCases);
    }
  }, []);

  return null;
};
