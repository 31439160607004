import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from '@mui/material';
import { shallow } from 'zustand/shallow';
import { useMyChats } from 'features/store';
import {
  BaseData,
  useDocument,
  ToastType,
  notice,
  useBoolean,
  useUser,
  useIsOnScreen,
  useApiSecurity,
} from 'features/common';
import { CreateLegalCaseMessagePayload } from 'features/common/services/chatApiService';
import { paymentRequiredErrorHandler } from 'features/common/errorHanders';
import { caseDetailsApiService } from 'features/case-details/CaseDetailsApiService';
import { legalCaseRepresentationApiService } from 'features/common/services/legalCaseRepresentationApiService';
import {
  MercureLegalCaseRepresentation,
  VideoCallRepresentation,
} from 'features/video-calling/hooks/useRepresentation';
import { createFileService } from 'features/common/services/filesService';

const MESSAGES_PER_PAGE = 75;

type EventMessage = BaseData<'LegalCaseChat' | 'FileSign' | 'File'> & {
  createdAt: string;
  id: string;
  updatedAt: string;
  legalCaseRepresentation?: MercureLegalCaseRepresentation | null;
};

export const useChatState = (isListOfChats?: boolean) => {
  const { deleteFileById } = createFileService();

  const {
    getMoreMessagesHandler,
    getMessagesHandler,
    makeViewedLegalCaseChatMessageHandler,
    createMessageHandler,
    uploadDocumentHandler,
    selectedUserChat,
    selectedChatData,
    totalItems,
    setSelectedUserChat,
    resetState,
    getChatFilesHandler,
    chatFilesData,
    getMoreChatFiles,
  } = useMyChats(
    state => ({
      totalItems: state.selectedChatData?.totalItems,
      selectedChatData: state.selectedChatData,
      getMoreMessagesHandler: state.getMoreMessagesHandler,
      getMessagesHandler: state.getMessagesHandler,
      makeViewedLegalCaseChatMessageHandler: state.makeViewedLegalCaseChatMessageHandler,
      setSelectedUserChat: state.setSelectedUserChat,
      selectedUserChat: state.selectedUserChat,
      createMessageHandler: state.createMessageHandler,
      uploadDocumentHandler: state.uploadDocumentHandler,
      resetState: state.resetState,
      getChatFilesHandler: state.getChatFilesHandler,
      getMoreChatFiles: state.getMoreChatFiles,
      chatFilesData: state.chatFilesData,
    }),
    shallow
  );

  const chatId = selectedUserChat?.id;

  const { aesDecrypt, aesEncrypt } = useApiSecurity();
  const { isAttorney, backendUser, isDocuSignActive } = useUser();
  const currentUserFirebaseId = backendUser?.firebaseUser;

  const messagesWrapperRef = useRef<HTMLDivElement | null>(null);
  const firstMessageRef = useRef<HTMLDivElement | null>(null);

  const [isChatMessagesLoading, setIsChatMessagesLoading] = useState(false);
  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const pages: Readonly<number> = Math.ceil(totalItems / MESSAGES_PER_PAGE);

  const isOnScreen = useIsOnScreen(isChatMessagesLoading ? null : firstMessageRef);

  const scrollToTheBottomHandler = () => {
    const messagesWrapper = messagesWrapperRef.current;
    if (messagesWrapper) {
      // Get the maximum scroll position (scrollHeight - clientHeight)
      const maxScrollPosition = messagesWrapper.scrollHeight - messagesWrapper.clientHeight;

      // Check if the user is scrolled up
      if (messagesWrapper.scrollTop < maxScrollPosition) {
        messagesWrapper.scrollTo({ top: messagesWrapper.scrollHeight, behavior: 'smooth' });
      }
    }
  };

  const onFetchLegalCaseChatMessagesHandler = async () => {
    setIsChatMessagesLoading(true);

    await getMessagesHandler({ chatId, params: { page: 1, itemsPerPage: MESSAGES_PER_PAGE } });
    setIsChatMessagesLoading(false);
    await makeViewedLegalCaseChatMessageHandler(currentUserFirebaseId);
  };

  const onFetchMoreLegalCaseChatMessagesHandler = async (nextPage: number) => {
    setIsFetchMoreLoading(true);
    await getMoreMessagesHandler({ chatId, params: { itemsPerPage: MESSAGES_PER_PAGE, page: nextPage } });
    setIsFetchMoreLoading(false);
  };

  useEffect(() => {
    if (chatId && selectedUserChat?.active) {
      onFetchLegalCaseChatMessagesHandler();
    }
  }, [chatId, selectedUserChat?.active]);

  useEffect(() => {
    if (isOnScreen && currentPage < pages) {
      const nextPage = currentPage + 1;
      onFetchMoreLegalCaseChatMessagesHandler(nextPage);
      setCurrentPage(nextPage);
    }
  }, [isOnScreen]);

  useEffect(() => {
    const messagesWrapper = messagesWrapperRef?.current;
    const maxScrollPosition = messagesWrapper?.scrollHeight - messagesWrapper?.clientHeight;

    if (messagesWrapper && messagesWrapper.scrollTop < maxScrollPosition) {
      messagesWrapper.scrollTop = messagesWrapper.scrollHeight;
    }
  }, [messagesWrapperRef, isChatMessagesLoading]);

  //chat header logic
  const [isSendRepresentationRequestLoading, setIsSendRepresentationRequestLoading] = useState(false);

  const caseId = selectedUserChat?.legalCase?.id;
  const isCaseOnCounsultation = selectedUserChat?.legalCase?.onConsultation;
  const firstName = selectedUserChat?.[isAttorney ? 'client' : 'attorney']?.userProfile?.firstName;
  const lastName = selectedUserChat?.[isAttorney ? 'client' : 'attorney']?.userProfile?.lastName;
  const logoURL = selectedUserChat?.[isAttorney ? 'client' : 'attorney']?.logo?.publicUrl;
  const remoteChatParticipantFirebaseId = selectedUserChat?.[isAttorney ? 'client' : 'attorney']?.firebaseUser;

  const [representation, setRepresentation] = useState<VideoCallRepresentation | null>(null);

  const { createRepresentation, approveRepresentation, deleteRepresentation } = legalCaseRepresentationApiService();
  const { getLegalCaseRepresentations } = caseDetailsApiService();

  const onGetRepresentationHandler = async () => {
    try {
      const { data } = await getLegalCaseRepresentations(caseId, { page: 1, itemsPerPage: 1 });

      const caseRepresenation = data['hydra:member']?.[0];

      if (representation && !caseRepresenation) setRepresentation(null);

      if (caseRepresenation) {
        setRepresentation({ representation: caseRepresenation, status: 'pending' });
      }
    } catch (error) {
      paymentRequiredErrorHandler(error, 'Failed to fetch case representation, please try again!');
      console.error(error);
    }
  };

  useEffect(() => {
    if (isCaseOnCounsultation && caseId) {
      onGetRepresentationHandler();
    }
  }, [caseId]);

  const onClickSendRepresentationButtonHandler = useCallback(async () => {
    try {
      setIsSendRepresentationRequestLoading(true);
      const caseId = selectedUserChat?.legalCase?.['@id'];

      await createRepresentation(caseId, 'chat');
      setIsSendRepresentationRequestLoading(false);
      notice(ToastType.SUCCESS, 'Successfully sent representation request!');
    } catch (error) {
      setIsSendRepresentationRequestLoading(false);
      paymentRequiredErrorHandler(error, 'Failed to send representation request, please try again!');
      console.error(error);
    }
  }, [caseId, selectedUserChat?.legalCase]);

  const onApproveRepresentationRequestHandler = useCallback(async () => {
    try {
      await approveRepresentation(representation?.representation?.id);

      await getMessagesHandler({
        chatId,
        params: { page: 1, itemsPerPage: 100 },
      });

      notice(ToastType.SUCCESS, 'Successfully approve representation offer!');
    } catch (error) {
      notice(ToastType.ERROR, 'Failed to approve representation offer, please try again!');
      paymentRequiredErrorHandler(error, 'Failed to send representation request, please try again!');
      console.error(error);
    }
  }, [representation?.representation?.id]);

  const onDeclineRepresentationRequestHandler = useCallback(async () => {
    try {
      await deleteRepresentation(representation?.representation?.id);

      notice(ToastType.SUCCESS, 'Successfully declined representation offer!');
    } catch (error) {
      paymentRequiredErrorHandler(error, 'Failed to decline representation offer, please try again!');
      console.error(error);
    }
  }, [representation?.representation?.id]);

  //chat input logic
  const [isFetchingNewMessages, setIsFetchingNewMessages] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onChangeInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const message = event.currentTarget.value;
    if (message.length > 250) return;
    setInputValue(message);
  };

  const onEnterPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      onSendMessageButtonHandler();
    }
  };

  const onSendMessageButtonHandler = async () => {
    const payload: CreateLegalCaseMessagePayload = {
      chat: `/legal_case_chats/${chatId}`,
      message: aesEncrypt(inputValue),
      type: 'text',
    };
    try {
      setInputValue('');
      setIsFetchingNewMessages(true);
      await createMessageHandler(payload, isListOfChats ? { isAttorney } : null);
    } catch (error) {
      setIsFetchingNewMessages(false);
      paymentRequiredErrorHandler(error, 'Failed to send message, please try again!');
      console.error(error);
    }
  };

  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [isDocumentsModalOpen, openDocumentsModal, closeDocumentsModal] = useBoolean(false);
  const [errorMesasage, setErrorMessage] = useState('');

  const onInputFileUploadHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];

      if (Number(file.size) / 1000 ** 2 > 25) {
        setErrorMessage('The document size should be equal or less than 25 MB');
        return;
      }

      try {
        const documentFormData = createFormDataHandler(file);

        setIsDocumentsLoading(true);
        await uploadDocumentHandler(chatId, documentFormData);
        setIsDocumentsLoading(false);

        errorMesasage && setErrorMessage('');
        event.target.value = '';
      } catch (error: any) {
        setIsDocumentsLoading(false);
        if (error?.response?.status === 422) {
          notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
        } else {
          notice(ToastType.ERROR, 'Failed to upload document, try again!');
        }
        console.error(error);
      }
    },
    [chatId]
  );

  const {
    closeDeleteDialogHandler,
    documentId,
    isDeleteDialogOpen,
    downloadButtonRef,
    onClickDeleteIconByIdHandler,
    createFormDataHandler,
    onClickDownloadDocumentHandler,
  } = useDocument();

  const onClickDeleteMessageFileIconHandler = (fileId: string) => {
    onClickDeleteIconByIdHandler(fileId);
  };

  const onClickDeleteFileHandler = async () => {
    try {
      setIsDocumentsLoading(true);
      closeDeleteDialogHandler();
      await deleteFileById(documentId);
      setIsDocumentsLoading(false);
      errorMesasage && setErrorMessage('');

      notice(ToastType.SUCCESS, 'Successfully deleted file!');
    } catch (error) {
      setIsDocumentsLoading(false);
      console.error(error);
      paymentRequiredErrorHandler(error, 'Failed to delete file, please try again!');
    }
  };

  const [message, setMessage] = useState<EventMessage | null>(null);

  const onNewMessageEventHandler = async () => {
    await getMessagesHandler({ chatId, params: { page: 1, itemsPerPage: MESSAGES_PER_PAGE } });
    scrollToTheBottomHandler();
    setIsFetchingNewMessages(false);
    await makeViewedLegalCaseChatMessageHandler(currentUserFirebaseId);
  };

  const debouncedOnNewMessageHandler = debounce(onNewMessageEventHandler, 500);

  const onReceiveEventNewMessageHandler = async (event: MessageEvent) => {
    const newMessage: EventMessage = JSON.parse(event.data);

    if (newMessage?.updatedAt !== message?.updatedAt) {
      setMessage(newMessage);

      if (newMessage?.legalCaseRepresentation) {
        if (newMessage?.legalCaseRepresentation?.status === 'pending') {
          setRepresentation({ representation: newMessage?.legalCaseRepresentation, status: 'pending' });
        }
        if (newMessage?.legalCaseRepresentation?.status === 'approve') {
          setRepresentation({ representation: newMessage?.legalCaseRepresentation, status: 'approve' });
        }
      }

      if (newMessage?.legalCaseRepresentation === null) {
        setRepresentation(null);
      }

      debouncedOnNewMessageHandler();
    }

    if (newMessage?.['@type'] === 'FileSign') {
      await getChatFilesHandler();
    }

    if (newMessage?.['@type'] === 'File') {
      await getChatFilesHandler();
    }
  };

  return {
    remoteChatParticipantFirebaseId,
    onApproveRepresentationRequestHandler,
    onDeclineRepresentationRequestHandler,
    representation,
    scrollToTheBottomHandler,
    onFetchLegalCaseChatMessagesHandler,
    onFetchMoreLegalCaseChatMessagesHandler,
    setCurrentPage,
    setIsChatMessagesLoading,
    errorMesasage,
    isDocumentsLoading,
    isDocumentsModalOpen,
    inputValue,
    downloadButtonRef,
    isDeleteDialogOpen,
    isFetchingNewMessages,
    onReceiveEventNewMessageHandler,
    closeDeleteDialogHandler,
    onClickDeleteMessageFileIconHandler,
    onClickDownloadDocumentHandler,
    closeDocumentsModal,
    onClickDeleteFileHandler,
    onInputFileUploadHandler,
    openDocumentsModal,
    onChangeInputHandler,
    onEnterPressHandler,
    onSendMessageButtonHandler,
    isChatMessagesLoading,
    pages,
    currentPage,
    isFetchMoreLoading,
    messagesWrapperRef,
    isOnScreen,
    firstMessageRef,
    selectedChatData,
    chatId,
    firstName,
    isCaseOnCounsultation,
    lastName,
    logoURL,
    isSendRepresentationRequestLoading,
    onClickSendRepresentationButtonHandler,
    currentUserFirebaseId,
    isAttorney,
    isDocuSignActive,
    selectedUserChat,
    setSelectedUserChat,
    resetState,
    aesDecrypt,
    getChatFilesHandler,
    chatFilesData,
    getMoreChatFiles,
  };
};
