import { FC, useState } from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import { UserWorkType } from 'features/auth/types';
import { AUTH_FLOW_STEPS, useAuth } from 'features/store';
import { IllustrationLayout, useAuthentication } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { IllustrationLogo } from 'features/common/components/IllustatrionLogo';

export const UserWorkTypeStep: FC = () => {
  // const { workType, setData, resetState } = useAuth(state => ({
  //   toStep: state.toStep,
  //   setData: state.setData,
  //   workType: state.workType,
  //   resetState: state.resetState,
  // }));

  const { workType, resetState, setData } = useAuthentication();

  const [userWorkType, setUserWorkType] = useState(workType);

  const onLabelClickHandler = (workType: UserWorkType) => () => setUserWorkType(workType);

  const onGoBackHandler = () => resetState();

  const onGoNextHandler = () =>
    setData({
      currentStep: AUTH_FLOW_STEPS.WORK_TYPE,
      data: userWorkType,
      activeStep:
        userWorkType === UserWorkType.CLIENT ? AUTH_FLOW_STEPS.INVITATION_CODE : AUTH_FLOW_STEPS.CREATE_USER_PROFILE,
    });

  return (
    <IllustrationLayout
      title="Let's Get Started"
      description="First, we need to know a bit about you. Are you here to find legal assistance or to offer your legal expertise? Select the option that best describes you:"
      onClickBack={onGoBackHandler}
      illustration={AuthFlowBackgrounds.AUTH_THREE}
      illustrationLogo={<IllustrationLogo bottom={10} right={10} />}
    >
      <MDBox display="flex" flexDirection="column" gap="8px">
        <MDBox mb={2}>
          <FormControlLabel
            checked={userWorkType === UserWorkType.ATTORNEY}
            onClick={onLabelClickHandler(UserWorkType.ATTORNEY)}
            control={<Radio />}
            label="I'm an Attorney"
            sx={{ fontSize: '16px' }}
          />
          <FormControlLabel
            checked={userWorkType === UserWorkType.CLIENT}
            onClick={onLabelClickHandler(UserWorkType.CLIENT)}
            control={<Radio />}
            label="I'm Seeking Legal Assistance"
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth sx={{ textTransform: 'none' }} onClick={onGoNextHandler}>
            Next
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
};
