import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { useRouter, useUser } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import MDBox from 'components/MDBox';

export const NotFoundTemplate: FC = () => {
  const { navigate } = useRouter();
  const { isAdmin } = useUser();

  const onClickHandler = () => navigate(isAdmin ? ROUTES.home : ROUTES.myLegalCases);

  return (
    <MDBox
      sx={({ functions, breakpoints, transitions }) => ({
        height: '100vh',
        p: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: transitions.create(['margin-left', 'margin-right'], {
          easing: transitions.easing.easeInOut,
          duration: transitions.duration.standard,
        }),
        [breakpoints.up('xl')]: {
          marginLeft: functions?.pxToRem(274),
        },
      })}
    >
      <Stack spacing={1.5} alignItems="center">
        <Stack sx={{ px: 1, textAlign: 'center' }}>
          <Typography variant="h1" component="h2" color="error">
            404
          </Typography>
          <Typography variant="h4" component="p">
            Oops! Page not found.
          </Typography>
          <Typography variant="body1" component="p" mt={1}>
            The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
          </Typography>
        </Stack>
        <MDButton onClick={onClickHandler} variant="gradient" color="info">
          Go home
        </MDButton>
      </Stack>
    </MDBox>
  );
};
