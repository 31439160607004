import {
  AdminNotificationResponse,
  AdminNotificationType,
  GetAdminNotificationsParams,
} from 'features/dashboard/AdminDashboardTemplate';
import { immer } from 'zustand/middleware/immer';
import { createStore } from '../createStore';

export type NotificationOptionItem = { id: AdminNotificationType; value: string };

export type AdminNotificationsFiltersData = Pick<
  GetAdminNotificationsParams,
  'createdAt[strictly_after]' | 'createdAt[strictly_before]'
> & { 'type[]': NotificationOptionItem[] };

export type AdminDashboardInitialState = {
  notifications: {
    data: AdminNotificationResponse[];
    totalItems: number | null;
    filtersData: AdminNotificationsFiltersData;
  };
};

export type AdminDashboardStateActions = {
  setNotifications: (payload: { data: AdminNotificationResponse[]; totalItems: number | null }) => void;

  setMoreNotifications: (payload: { data: AdminNotificationResponse[] }) => void;
  setFiltersData: (params?: AdminNotificationsFiltersData) => void;
};

const initialState: AdminDashboardInitialState = {
  notifications: {
    data: [],
    totalItems: null,
    filtersData: { 'createdAt[strictly_after]': '', 'createdAt[strictly_before]': '', 'type[]': [] },
  },
};

export const useAdminDashboard = createStore<AdminDashboardInitialState & AdminDashboardStateActions>(
  immer(set => ({
    ...initialState,
    setNotifications: ({ data, totalItems }) => {
      set(state => {
        state.notifications.data = data;
        state.notifications.totalItems = totalItems;
      });
    },
    setMoreNotifications: ({ data }) => {
      set(state => {
        state.notifications.data.push(...data);
      });
    },
    setFiltersData: filtersData => {
      set(state => {
        state.notifications.filtersData = filtersData;
      });
    },
  })),
  'adminDashboard',
  initialState
);
