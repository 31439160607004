import { HeadquarterAddress } from 'features/company-list';
import { backendApiClient, notice, PATCH_HEADERS, ToastType } from '../../common';

const BASE_SECURITY_PROFILE_API_URL = '/security/profile';

type PatchedUserProfile = {
  personTitle: string;
  firstName: string;
  lastName: string;
  country: string;
  state: string;
  town: string;
  defaultPostalAddress: string;
};

export type CreatePostalAddressPayload = {
  type: 'home' | 'business';
  title: string;
  nameOfAddressee: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  town: string;
  country: string;
  state: string;
};

export const createBackendAuthService = () => {
  const getUserProfile = async () => {
    try {
      return await backendApiClient({}).get(BASE_SECURITY_PROFILE_API_URL);
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong!');
      console.error({ error });
    }
  };

  const updateUserProfile = async (userProfileUpdatedFields: Partial<PatchedUserProfile>) => {
    try {
      const userProfile = await backendApiClient({}).patch(
        BASE_SECURITY_PROFILE_API_URL,
        { userProfile: userProfileUpdatedFields },
        { headers: PATCH_HEADERS }
      );
      return userProfile;
    } catch (error) {
      notice(ToastType.ERROR, 'Something went wrong!');
      console.error({ error });
    }
  };

  const createPostalAddress = async (payload: CreatePostalAddressPayload) =>
    await backendApiClient({}).post('/postal_addresses', payload);

  const updatePostalAddress = async (id: string, payload: Partial<CreatePostalAddressPayload>) =>
    await backendApiClient({ headers: PATCH_HEADERS }).patch<HeadquarterAddress>(`/postal_addresses/${id}`, payload);

  const connectDocusignToProfile = async (code: string) => {
    return await backendApiClient({}).post(`/security/profile/integrations/docusign/access_code`, {
      code,
    });
  };
  const disconnectDocusignFromProfile = async () => {
    return await backendApiClient({}).post(
      '/security/profile/integrations/docusign/disconnect',
      {},
      { headers: { 'Content-Type': 'application/ld+json' } }
    );
  };

  const connectClioToProfile = async (code: string) =>
    await backendApiClient({}).post('clio/integrations/access_code', {
      code,
    });

  const disconnectClioFromProfile = async () => await backendApiClient({}).post('clio/integrations/disconnect', {});

  return {
    updatePostalAddress,
    connectClioToProfile,
    disconnectClioFromProfile,
    getUserProfile,
    updateUserProfile,
    createPostalAddress,
    connectDocusignToProfile,
    disconnectDocusignFromProfile,
  };
};
