import { FC } from 'react';

import { Card, CardContent, Stack } from '@mui/material';

import MDTypography from 'components/MDTypography';

type DisabledChatContentProps = {
  isAttorney: boolean;
};

export const DisabledChatContent: FC<DisabledChatContentProps> = ({ isAttorney }) => {
  return (
    <Card sx={{ flex: 1, p: 2, overflow: 'hidden', minHeight: 300 }}>
      <CardContent sx={{ height: 1 }}>
        <Stack spacing={1.5} height={1} alignItems="center" justifyContent="center" textAlign="center">
          <MDTypography variant="body2" fontWeight={400}>
            {isAttorney
              ? `Access to the messaging system requires a paid subscription. If you don't have a subscription, you can still
            voice or video call the client`
              : `Your attorney will reach out to you soon via audio or video call through the app. Please keep your device nearby and notifications enabled`}
          </MDTypography>
        </Stack>
      </CardContent>
    </Card>
  );
};
