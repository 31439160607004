import { FC } from 'react';
import { createMicrophoneAudioTrack } from 'agora-rtc-react';
import { Grid } from '@mui/material';
import { DashboardLayout, useUser } from 'features/common';
import { useAudioVideoChat, useNotificationsCenter } from 'features/store';
import { RepresentationRequestModal } from './Components/RepresentationRequestModal';
import { SideNavInfo } from './Components/SideNavInfo';
import { Loader } from 'features/common/components/Loader';
import { PermissionsCard } from './Components/PermissionsCard';
import { AudioVideoRoom } from './AudioVideoRoom';
import { useAgoraState } from './hooks/useAgora.state';
import { useAudioVideoRoomState } from './hooks/useAudioVideoRoom.state';

const useMicrophoneTrack = createMicrophoneAudioTrack();

export const AudioRoomTemplate: FC = () => {
  const { ready, track: audioTrack } = useMicrophoneTrack();

  const { backendUserFirebaseId, isAttorney } = useUser();
  const {
    representationId,
    setRepresentationId,
    eventData,
    RTCprops,
    startCall,
    joinCall,
    resetState,
    isScheduledCall,
    eventId,
    caseId,
    startCallPropsData,
    updateEventdata,
  } = useAudioVideoChat();

  const {
    setCallInvitationMessage,
    pushNotificationData: { inviteCallData },
  } = useNotificationsCenter();

  const {
    secondsToLeft,
    isReconnecting,
    user,
    remoteParticipant,
    localParticipant,
    start,
    isRunning,
    isRemoteUserAudioMuted,
    isRemoteUserVideoMuted,
    timerRef,
    onClickLeaveButtonHandler,
    onCloseAgoraConnectionHandler,
  } = useAgoraState({
    setCallInvitationMessage,
    resetState,
    isScheduledCall,
    currentUserFirebaseId: backendUserFirebaseId,
    participants: eventData?.participants,
    eventId: eventData?.id,
    isAttorney,
    representationId,
    eventStatus: eventData?.status,
    audioTrack,
    eventType: startCallPropsData?.eventType,
    backendUserFirebaseId,
    inviteCallData,
    isReady: ready,
    RTCprops,
    videoTrack: null,
  });

  const { isChatOpen, isLoading, onClickChatIconButtonHandler } = useAudioVideoRoomState({
    caseId: startCallPropsData?.caseId,
    endParticipantFirebaseId: startCallPropsData?.endParticipantFirebaseId,
    backendUserFirebaseId,
    isReady: ready,
    isScheduledCall,
    eventId,
    onCloseAgoraConnectionHandler,
    joinCall,
    startCall,
  });

  const legalCaseData = eventData?.legalCase;

  const isLoader = !start && !!audioTrack && ready;

  return (
    <DashboardLayout>
      <Grid container spacing={2} flex={1}>
        {!ready ? (
          <PermissionsCard eventType={startCallPropsData?.eventType} />
        ) : isLoading ? (
          <Loader />
        ) : RTCprops.channel ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={isChatOpen ? 5 : 4}
              sx={{
                transition: 'all 0.2s ease-in-out',
              }}
            >
              <SideNavInfo
                caseNumber={legalCaseData?.caseNumber}
                caseLawtypeTitle={legalCaseData?.caseLawType?.title}
                countryName={legalCaseData?.country?.name}
                countyName={legalCaseData?.county?.name}
                isChatOpen={isChatOpen}
                onClickChatIconButtonHandler={onClickChatIconButtonHandler}
                eventData={eventData}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={isChatOpen ? 7 : 8}
              sx={{ display: 'flex', flexDirection: 'column', width: 1, transition: 'all 0.2s ease-in-out' }}
            >
              <AudioVideoRoom
                updateEventdata={updateEventdata}
                setRepresentationId={setRepresentationId}
                eventId={eventData?.id}
                isAttorney={isAttorney}
                isChatOpen={isChatOpen}
                legalCaseId={legalCaseData?.['@id']}
                eventType={eventData?.type}
                isReconnecting={isReconnecting}
                onLeaveChannel={onClickLeaveButtonHandler}
                onChatIconClickHandler={onClickChatIconButtonHandler}
                isCaseOnConsultation={legalCaseData?.onConsultation}
                isRunning={isRunning}
                timerRef={timerRef}
                isRemoteUserAudioMuted={isRemoteUserAudioMuted}
                isRemoteUserVideoMuted={isRemoteUserVideoMuted}
                remoteParticipant={user}
                localParticipant={localParticipant}
                eventRemoteParticipant={remoteParticipant}
                isCalling={!!secondsToLeft}
                audioTrack={audioTrack}
                videoTrack={null}
                isLoading={isLoader}
              />
            </Grid>
          </>
        ) : !isAttorney && representationId && caseId ? (
          <RepresentationRequestModal legalCaseId={caseId} setRepresentationId={setRepresentationId} />
        ) : null}
      </Grid>
    </DashboardLayout>
  );
};
