import { BaseData, backendApiClient } from 'features/common';

const BASE_REQUEST_API_URL: Readonly<string> = '/legal_case_representations';

export type Representation = BaseData<'LegalCaseRepresentation'> & {
  id: string;
  legalCase: string;
  createdAt: string;
  status: string;
};

export type RepresentationType = 'chat' | 'video' | 'audio';

export const legalCaseRepresentationApiService = () => {
  const createRepresentation = async (caseId: string, type: RepresentationType) => {
    return await backendApiClient({}).post<Representation>(BASE_REQUEST_API_URL, {
      legalCase: caseId,
      type,
    });
  };

  const deleteRepresentation = async (id: string) => await backendApiClient({}).delete(`${BASE_REQUEST_API_URL}/${id}`);

  const approveRepresentation = async (id: string) =>
    await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<Representation>(`${BASE_REQUEST_API_URL}/${id}/approve`, {});

  return {
    createRepresentation,
    deleteRepresentation,
    approveRepresentation,
  };
};
