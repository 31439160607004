import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BaseFormField, notice, ToastType, useAuthentication, useRouter } from 'features/common';
import { createFirebaseService } from 'features/auth';
import { ROUTES } from 'features/common/variables/routes';
import { FirebaseSignUpFormSchema, firebaseSignUpFormValidationSchema } from 'features/auth/formSchema';
import MDBox from 'components/MDBox';
import { BasePasswordFormField } from 'features/common/components/BasePasswordFormField';
import { useResponsive } from 'features/common/hooks/useResponsive';
import { AuthProvidersButtons } from '../AuthProvidersButtons';
import { RedirectButton } from '../RedirectButton';

const { signUpWithEmail } = createFirebaseService();

export const FirebaseSignUpWithEmail: FC = () => {
  const { params } = useRouter();

  const {
    onSendConfirmationEmailHandler,
    onAuthProviderButtonClickHandler,
    acceptAdminInvitationHandler,
    onConnectBackendUser,
  } = useAuthentication();

  const {
    register,
    formState: { isDirty, isSubmitting, isValid, errors },
    handleSubmit,
  } = useForm<FirebaseSignUpFormSchema>({
    resolver: yupResolver(firebaseSignUpFormValidationSchema),
    mode: 'onTouched',
  });

  const onFormSubmitHandler = handleSubmit(async (formData: FirebaseSignUpFormSchema) => {
    const { email, password } = formData;

    try {
      const userCredential = await signUpWithEmail({ email, password });

      if (userCredential) {
        !userCredential?.user?.emailVerified &&
          (await onSendConfirmationEmailHandler({ firebaseUser: userCredential?.user, isDisableNotice: true }));

        await acceptAdminInvitationHandler(params?.accessCode);

        await onConnectBackendUser({ user: userCredential?.user });
      }
    } catch (error) {
      console.error(error);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  });

  const isNextButtonDisabled = !isValid || isSubmitting || !isDirty;

  const isSmallScreen = useResponsive('between', 'xs', 'sm');
  const isLgScreens = useResponsive('up', 'md');

  return (
    <form onSubmit={onFormSubmitHandler}>
      <MDBox display="flex" flexDirection="column" gap="8px">
        <BaseFormField
          formInputProps={{
            ...register('email'),
            type: 'email',
            label: 'Email',
            fullWidth: true,
          }}
          errorValue={errors['email']?.message}
        />

        <BasePasswordFormField
          formFieldProps={{
            pb:
              isSmallScreen && errors?.['password']?.type === 'matches'
                ? '45px'
                : isLgScreens && errors?.['password']?.type === 'matches'
                ? '30px'
                : '18px',
          }}
          formInputProps={{
            ...register('password'),
            label: 'New password',
            fullWidth: true,
          }}
          errorValue={errors?.['password']?.message}
        />

        <BasePasswordFormField
          formInputProps={{
            ...register('passwordConfirmation'),
            label: 'Confirm Password',
            fullWidth: true,
          }}
          errorValue={errors?.['passwordConfirmation']?.message}
        />

        <AuthProvidersButtons
          isNextButtonDisabled={isNextButtonDisabled}
          isProviderButtonDisabled={isSubmitting}
          onAuthProviderButtonClickHandler={onAuthProviderButtonClickHandler(true)}
        />
        <RedirectButton routeTo={ROUTES.signIn} />
      </MDBox>
    </form>
  );
};
