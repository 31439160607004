import { FC, ChangeEvent, useState, useEffect } from 'react';
import { PhoneAuthProvider, UserCredential } from '@firebase/auth';
import { AUTH_FLOW_STEPS } from 'features/store';
import { BaseFormField, IllustrationLayout, ToastType, notice, useAuthentication, useRouter } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { useCountdown } from 'features/common/hooks/useCountdown';
import { Illustrationtitle } from 'features/common/components/IllustatrionLogo';
import { createFirebaseService } from '../services';
import { useCaptchaVerifier } from '../contexts';
import { AuthFlowBackgrounds } from '../variables/illustartion';

export const VerifyPhoneStep: FC = () => {
  const { onConnectBackendUser, firebasePhoneAuthData, acceptAdminInvitationHandler, toStep } = useAuthentication();

  const { params } = useRouter();

  const { sendVerificationCodeOnPhoneNumber, authWithCredential } = createFirebaseService();
  const { firebaseAppVerifier } = useCaptchaVerifier();
  const { isFinished: isCountDownFinished, countdown, startCountdown } = useCountdown(30);

  useEffect(() => {
    startCountdown();
  }, []);

  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(firebasePhoneAuthData.verificationId || '');

  const onChangeInputValueHandler = (event: ChangeEvent<HTMLInputElement>) => setVerificationCode(event.target.value);

  const onVerifyCodeButtonClickHandler = async (): Promise<UserCredential | void> => {
    try {
      const authCredential = PhoneAuthProvider.credential(verificationId, verificationCode);

      const response = await authWithCredential(authCredential);

      if (response && response.user?.uid) {
        await acceptAdminInvitationHandler(params.accessCode);
        await onConnectBackendUser({ user: response?.user });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSendCodeHandler = async () => {
    try {
      const confirmationResult = await sendVerificationCodeOnPhoneNumber(
        firebasePhoneAuthData.phoneNumber,
        firebaseAppVerifier
      );
      if (confirmationResult) {
        notice(ToastType.SUCCESS, "We've sent verification message to your phone!", { autoClose: false });
        setVerificationId(confirmationResult.verificationId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resendCodeHandler = async () => {
    startCountdown();
    await onSendCodeHandler();
  };

  const isVerifyCodeBtnDisabled = false;

  const onClickBackButton = () => toStep(AUTH_FLOW_STEPS.FIREBASE_AUTH);

  return (
    <IllustrationLayout
      title="Verification code"
      description={firebasePhoneAuthData.phoneNumber && `Code is sent to ${firebasePhoneAuthData.phoneNumber}`}
      onClickBack={onClickBackButton}
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      illustrationLogo={<Illustrationtitle />}
    >
      <MDBox>
        <MDBox>
          <BaseFormField
            formInputProps={{
              value: verificationCode,
              onChange: onChangeInputValueHandler,
              type: 'text',
              label: 'Verification Code',
              fullWidth: true,
            }}
          />
          <MDBox display="flex" justifyContent="flex-end">
            {isCountDownFinished ? (
              <MDButton onClick={resendCodeHandler}>Resend sms</MDButton>
            ) : (
              <MDTypography sx={{ fontSize: '14px' }}>Resend sms after {countdown}</MDTypography>
            )}
          </MDBox>
          <MDBox mt={4} mb={1} display="flex" flexDirection="column" gap="20px">
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
              fullWidth
              sx={{ textTransform: 'none' }}
              onClick={onVerifyCodeButtonClickHandler}
              disabled={isVerifyCodeBtnDisabled}
            >
              Next
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
};
