import { Pagination } from 'features/case-law-types';
import { BaseData, ListOfItems, PATCH_HEADERS, backendApiClient } from 'features/common';

const BASE_ADMIN_DASHBOARD_API_URL = '/admin_feeds';

export type AdminNotificationType =
  | 'attorney_follow_up'
  | 'client_registration'
  | 'legal_case_creation'
  | 'legal_case_consultation_creation'
  | 'legal_case_consultation_accepted'
  | 'legal_case_representation_creation'
  | 'legal_case_representation_accepted';

export type GetAdminNotificationsParams = Pagination &
  Partial<{
    'createdAt[before]': string;
    'createdAt[strictly_before]': string;
    'createdAt[after]': string;
    'createdAt[strictly_after]': string;
    message: string;
    type: AdminNotificationType;
    'type[]': AdminNotificationType[];
  }>;

export type AdminNotificationResponse = BaseData<''> & {
  id: string;
  type: AdminNotificationType;
  message: string;
  createdAt: string;
  viewedAt: string | null;
};

export const adminDashboardApiService = () => {
  const getAdminNotifications = async (params?: GetAdminNotificationsParams) =>
    await backendApiClient({}).get<ListOfItems<AdminNotificationResponse[]>>(BASE_ADMIN_DASHBOARD_API_URL, {
      params,
    });

  const viewAdminNotification = async (id: string) =>
    await backendApiClient({}).patch<AdminNotificationResponse>(
      `${BASE_ADMIN_DASHBOARD_API_URL}/${id}/viewed`,
      {},
      { headers: PATCH_HEADERS }
    );

  const readAllAdminNotifications = async () =>
    await backendApiClient({}).post(`${BASE_ADMIN_DASHBOARD_API_URL}/view_all`, {});

  return {
    getAdminNotifications,
    readAllAdminNotifications,
    viewAdminNotification,
  };
};

export const ITEMS_PER_PAGE = 30;
