import { FC, memo, useMemo, useState } from 'react';
import { Autocomplete, Stack } from '@mui/material';
import { endOfDay, isEqual, startOfDay } from 'date-fns';
import { BaseFormField } from 'features/common';
import MDButton from 'components/MDButton';
import { BaseDatePicker } from 'features/common/components/BaseTimePicker';
import { AdminNotificationsFiltersData, NotificationOptionItem } from 'features/store';

type FiltersMenuContentProps = {
  filtersData: AdminNotificationsFiltersData;
  onApplyFilters: (params: AdminNotificationsFiltersData) => void;
};

const TYPES_OPTIONS: NotificationOptionItem[] = [
  { id: 'attorney_follow_up', value: 'Attorney Follow-Up' },
  { id: 'client_registration', value: 'Client Registration' },
  { id: 'legal_case_creation', value: 'Legal Case Creation' },
  { id: 'legal_case_consultation_creation', value: 'Consultation Requested' },
  { id: 'legal_case_consultation_accepted', value: 'Consultation Accepted' },
  { id: 'legal_case_representation_creation', value: 'Representation Requested' },
  { id: 'legal_case_representation_accepted', value: 'Representation Accepted' },
];

export const FiltersMenuContent: FC<FiltersMenuContentProps> = memo(({ filtersData, onApplyFilters }) => {
  const [notificationTypes, setNotificationTypes] = useState<NotificationOptionItem[]>(filtersData['type[]']);

  const onChangeTypesHandler = (_: React.SyntheticEvent<Element, Event>, values: NotificationOptionItem[]) => {
    setNotificationTypes(values);
  };

  const onApplyFiltersHandler = () => {
    onApplyFilters({
      'type[]': notificationTypes,
      ...(startDate && {
        'createdAt[strictly_after]': startDate.toISOString(),
      }),
      ...(endDate && {
        'createdAt[strictly_before]': endDate.toISOString(),
      }),
    });
  };

  const onResetButtonClickHandler = () => {
    startDate && setStartDate(null);
    endDate && setEndDate(null);
    notificationTypes.length && setNotificationTypes([]);
  };

  const isDefaultArraySameAsCurrent = useMemo(() => {
    if (filtersData['type[]'].length !== notificationTypes.length) return false;

    const sortedCurrent = [...notificationTypes].sort((a, b) => (a > b ? 1 : -1));
    const sortedDefault = [...filtersData['type[]']].sort((a, b) => (a > b ? 1 : -1));

    for (let i = 0; i < sortedCurrent.length; i++) {
      if (sortedCurrent[i] !== sortedDefault[i]) {
        return false;
      }
    }

    return true;
  }, [notificationTypes, filtersData['type[]']]);

  const filtersDataCreatedAfter = filtersData['createdAt[strictly_after]'];
  const filtersDataCreatedBefore = filtersData['createdAt[strictly_before]'];

  const [startDate, setStartDate] = useState(filtersDataCreatedAfter ? new Date(filtersDataCreatedAfter) : null);

  const onChangeStartDate = (value: Date) => setStartDate(startOfDay(value));

  const onClearStartDate = () => setStartDate(null);

  const [endDate, setEndDate] = useState(filtersDataCreatedBefore ? new Date(filtersDataCreatedBefore) : null);

  const onChangeEndDate = (value: Date) => setEndDate(endOfDay(value));

  const onClearEndDate = () => setEndDate(null);

  const isResetButtonDisabled = !startDate && !endDate && !notificationTypes.length;

  const isApplyButtonDisabled =
    isDefaultArraySameAsCurrent &&
    isEqual(startDate || new Date(), filtersDataCreatedAfter ? new Date(filtersDataCreatedAfter) : new Date()) &&
    isEqual(endDate || new Date(), filtersDataCreatedBefore ? new Date(filtersDataCreatedBefore) : new Date());

  return (
    <Stack height={1} spacing={2} position="relative">
      <Stack spacing={2.5} mt={2} flexGrow={1}>
        <Stack spacing={2.5}>
          <BaseDatePicker
            label="Start date"
            disableFuture
            value={startDate}
            onChange={onChangeStartDate}
            onClickClearButtonHandler={onClearStartDate}
          />

          <BaseDatePicker
            label="End date"
            disableFuture
            value={endDate}
            onChange={onChangeEndDate}
            onClickClearButtonHandler={onClearEndDate}
          />

          <Autocomplete
            value={notificationTypes}
            onChange={onChangeTypesHandler}
            getOptionLabel={option => option.value}
            options={TYPES_OPTIONS}
            freeSolo={false}
            multiple
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disableClearable={false}
            sx={{
              '.MuiInputBase-root': {
                py: '4.5px',
              },
            }}
            renderInput={params => (
              <BaseFormField
                formFieldProps={{ pb: 1 }}
                formInputProps={{
                  ...params,
                  label: 'Notification type',
                  placeholder: 'Choose a type',
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                }}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack spacing={2} pb={2} direction="column" position="absolute" sx={{ bottom: 0, width: 1 }}>
        <MDButton
          variant="gradient"
          color="dark"
          disabled={isResetButtonDisabled}
          onClick={onResetButtonClickHandler}
          sx={{ textTransform: 'none' }}
        >
          Clear filters
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          disabled={isApplyButtonDisabled}
          onClick={onApplyFiltersHandler}
          sx={{ textTransform: 'none' }}
        >
          Apply filters
        </MDButton>
      </Stack>
    </Stack>
  );
});
