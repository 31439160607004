import { v4 as uuidv4 } from 'uuid';
import { syncTabs } from 'zustand-sync-tabs';
import { GetConfigsResponse, settingsApiService } from 'features/settings/SettingsApiService';
import { createBackendUserService } from 'features/common/services';
import { AgoraEventType } from 'features/common/services/agoraEventApiService';
import { createStore, resetters } from '../createStore';
import { firebaseAuth } from 'features/auth';

type CallData = {
  eventId: string;
  endParticipantFirebaseId: string;
  eventType: AgoraEventType;
};

export type LockScreenState = {
  isDeactivateIdleTimer: boolean;
  isResetIdleTimer: boolean;
  isRedirectToLockScreen: boolean;
  idleConfigData: GetConfigsResponse;
  deviceId: string;
  lastIdleConfigExecutionTime: string | null;
  callData: CallData;
  lastActivity: null | number;
};

export type LockScreenActions = {
  setIsDeactivateIdleTimer: (value?: boolean) => void;
  setIsResetIdleTimer: (value: boolean) => void;
  setIsRedirectToLockScreen: (value: boolean) => void;
  onGetTimeToIdleHandler: () => Promise<void>;
  registerDeviceHandler: () => Promise<void>;
  resetStateHandler: () => void;
  setCallData: (callData: CallData) => void;
  setActivityTime: (time: number) => void;
  onIdleHandler: (isAdmin: boolean, pushToken: string) => Promise<void>;
};

export const CONFIG_ID = 'security_pin_lock_expired_seconds';

const initialState: LockScreenState = {
  isDeactivateIdleTimer: false,
  isResetIdleTimer: false,
  isRedirectToLockScreen: false,
  idleConfigData: {} as GetConfigsResponse,
  deviceId: '',
  lastIdleConfigExecutionTime: null,
  callData: { endParticipantFirebaseId: '', eventId: '', eventType: 'video_call' },
  lastActivity: null,
};

const { getConfigById } = settingsApiService();
const { registerDevice } = createBackendUserService();

const USERS_STATE_NAMES = [
  'audioVideoChat',
  'myLegalCases',
  'myClients',
  'caseDetails',
  'myChats',
  'myCompany',
  'myEvents',
  'legalCasesFeed',
];
const ADMIN_STATE_NAMES = ['adminSettings', 'userReviews'];

const { logoutBackendUser } = createBackendUserService();

export const useLockScreen = createStore<LockScreenState & LockScreenActions>(
  syncTabs(
    set => ({
      ...initialState,
      onIdleHandler: async (isAdmin: boolean, pushToken: string) => {
        try {
          await logoutBackendUser([pushToken], false);
        } catch (error) {
          console.error(error);
        }
        set(state => ({ ...state, isRedirectToLockScreen: true }));
        await firebaseAuth.signOut();

        const stateNames = isAdmin ? ADMIN_STATE_NAMES : USERS_STATE_NAMES;

        resetters.forEach(resetter => {
          if (stateNames.includes(resetter?.name)) resetter?.resetFunction();
        });
      },
      setActivityTime: time => {
        set(state => ({ ...state, lastActivity: time }));
      },
      setIsDeactivateIdleTimer: (isDeactivateIdleTimer = true) => {
        set({ isDeactivateIdleTimer });
      },
      setCallData: callData => {
        set({ callData });
      },
      resetStateHandler: () => {
        set(state => (state.isDeactivateIdleTimer ? { ...initialState, isDeactivateIdleTimer: true } : initialState));
      },
      setIsResetIdleTimer: isResetIdleTimer => {
        set({ isResetIdleTimer });
      },
      setIsRedirectToLockScreen: isRedirectToLockScreen => {
        set(state => ({ ...state, isRedirectToLockScreen }));
      },
      onGetTimeToIdleHandler: async () => {
        const { data } = await getConfigById(CONFIG_ID);
        set({ idleConfigData: data, lastIdleConfigExecutionTime: Date.now().toString() });
      },
      registerDeviceHandler: async () => {
        const deviceId = uuidv4();
        await registerDevice({ deviceId, platformType: 'web' });
        set({ deviceId });
      },
    }),
    { name: 'lock-screen' }
  ),
  'lockScreen',
  initialState
);
