import { FC, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDBadge from 'components/MDBadge';

// Material Dashboard 2 PRO React TS examples components
import Breadcrumbs from 'examples/Breadcrumbs';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from 'examples/Navbars/DashboardNavbar/styles';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from 'context';
import { ROUTES } from 'features/common/variables/routes';
import { AllNotificationsSideMenuContent, NotificationCenterModal } from 'features/notification-center';
import { usePopover } from 'features/common/hooks/usePopover';
import { useBoolean } from 'features/common/hooks';
import { useAppStatisticContextState } from 'features/contexts/AppStatisticsContextProvider';
import { SideMenu } from '../SideMenu';

type DashboardNavbarProps = {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
  isDisabled?: boolean;
  isAdmin: boolean;
};

export const DashboardNavbar: FC<DashboardNavbarProps> = ({ isAdmin, absolute, light, isMini, isDisabled = false }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, darkMode } = controller;

  const location = useLocation();
  const route = location.pathname.split('/').slice(1);

  const { userStatistic } = useAppStatisticContextState();

  useEffect(() => {
    setTransparentNavbar(dispatch, true);
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const { handleClosePopover, handleOpenPopover, openPopover } = usePopover();

  const [isAllMenuOpen, openAllMenu, closeAllMenu] = useBoolean(false);

  const onClickViewAllNotificationsButtonHandler = () => {
    openAllMenu();
    handleClosePopover();
  };

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }: { palette: any; functions: any }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const title = location.pathname.includes('/cases/') ? 'case details' : route[route.length - 1];
  return (
    <AppBar
      position="static"
      color="inherit"
      sx={theme => navbar(theme, { transparentNavbar, absolute, light, darkMode, display: 'none' })}
    >
      <Toolbar sx={navbarContainer}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} mr={1} sx={theme => navbarRow(theme, { isMini })}>
          {!isDisabled && <Breadcrumbs isAdmin={isAdmin} icon="home" title={title} route={route} light={light} />}
        </MDBox>
        {isMini ? null : (
          <MDBox sx={theme => navbarRow(theme, { isMini })}>
            <MDBox color={light ? 'white' : 'inherit'}>
              <Link to={ROUTES.profile}>
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton size="small" disableRipple color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              {!isAdmin && (
                <IconButton
                  data-testid="notifications-button"
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleOpenPopover}
                >
                  <MDBadge badgeContent={userStatistic?.newUserFeedCount} color="error" size="xs" circular>
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </MDBadge>
                </IconButton>
              )}

              {openPopover && (
                <NotificationCenterModal
                  isOpenMenu={openPopover}
                  onCloseMenuHandler={handleClosePopover}
                  onViewAllButtonClickHandler={onClickViewAllNotificationsButtonHandler}
                />
              )}

              <SideMenu isOpen={isAllMenuOpen} onClose={closeAllMenu} title="All notifications" customWidth={450}>
                <AllNotificationsSideMenuContent closeMenuHandler={closeAllMenu} />
              </SideMenu>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
};

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
