import { useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { IllustrationLayout, useAuthentication, useRouter } from 'features/common';
import { ROUTES } from 'features/common/variables/routes';
import MDButton from 'components/MDButton';
import { AuthFlowBackgrounds } from '../variables/illustartion';

export const VerifyEmailStep = () => {
  const { onSendConfirmationEmailHandler, backendUser, firebaseUser } = useAuthentication();

  const router = useRouter();
  const onClickBackButton = () => router.push(ROUTES.signIn);

  const [isLoading, setIsLoading] = useState(false);

  const onClickResendConfirmationEmail = async () => {
    setIsLoading(true);
    await onSendConfirmationEmailHandler({ firebaseUser });
    setIsLoading(false);
  };

  return (
    <IllustrationLayout
      title="To continue using LegalBeagle application you should confirm your email"
      description={backendUser?.email}
      onClickBack={onClickBackButton}
      illustration={AuthFlowBackgrounds.AUTH_ONE}
      contentWrapperProps={{
        lg: 10,
        xl: 11,
        sm: 9,
      }}
    >
      <Grid container></Grid>
      <Stack spacing={2.5}>
        <Typography variant="body2" color="text.main" textAlign="center">
          To do that please go to your email client, find an email from LegalBeagle and click the link to confirm your
          email.
        </Typography>
        <Stack alignItems="center" justifyContent="center" direction={{ xs: 'column', md: 'row' }} gap={1.5}>
          <MDButton fullWidth variant="outlined" color="info">
            Contact Support
          </MDButton>
          <MDButton
            isLoading={isLoading}
            disabled={isLoading}
            fullWidth
            onClick={onClickResendConfirmationEmail}
            variant="contained"
            color="info"
          >
            Resent Confirmation Email
          </MDButton>
        </Stack>
      </Stack>
    </IllustrationLayout>
  );
};
