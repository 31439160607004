import { memo } from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTES } from 'features/common/variables/routes';
import { useUser } from 'features/common/hooks';

export const PINcodeAlert = memo((): JSX.Element | null => {
  const { isPinCodeActive } = useUser();

  return (
    <>
      {!isPinCodeActive && (
        <Card
          sx={{
            display: 'flex',
            width: 1,
            height: 1,
            borderRadius: '12px',
            fontWeight: 500,
            my: 1,
            backgroundColor: ({ palette }) => palette.warning.main,
          }}
        >
          <CardContent sx={{ p: 2, pb: '16px !important' }}>
            <Stack spacing={1}>
              <Typography variant="body2" fontWeight={400} sx={{ color: theme => theme.palette.common.white }}>
                To ensure the safety of your data, we strongly recommend setting up a security lock screen on your
                device. You can easily enable it by following this{' '}
                <Typography variant="body2" component={Link} to={ROUTES.security} color="info.main" fontWeight={500}>
                  settings.
                </Typography>
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
});
