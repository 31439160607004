import { CaseFile, GetMyLegalCasesResponse } from 'features/my-legal-cases/MyLegalCasesApiService';
import { Pagination } from 'features/case-law-types';
import { UserProfile } from 'features/company-list';
import { GetMyClientsParams } from 'features/my-clients/MyClientsApiService';
import { GetMyLegalCaseDetailsResponse } from 'features/case-details/CaseDetailsApiService';
import { ENCRYPTED_HEADER, backendApiClient } from '../api';
import { BaseData, ListOfItems } from '../types';
import { Logo } from './types';

const BASE_LEGAL_CASE_CHAT_API_URL = '/legal_case_chats';

export type CreateLegalCaseChatResponse = BaseData<'LegalCaseChat'> & {
  '@context': string;
};

//message type
export type CreateLegalCaseMessagePayload = {
  chat: string;
  message?: string;
  file?: string;
  type: MessageType;
};

export type Offer = {
  '@context': 'string';
  '@id': 'string';
  '@type': 'string';
  id: string;
  status: 'pending' | string;
};

export type MessageOwner = BaseData<'User'> & {
  firebaseUser: string;
  userProfile: UserProfile;
  logo: Logo | null;
};

export type MessageType = 'text' | 'file' | 'prompt' | 'prompt_representation_decline';

export type MessageRepresentation = {
  '@id': string;
  '@type': string;
  id: string;
  status: 'approve' | 'decline' | 'pending';
};

export type CreateLegalCaseMessageResponse = BaseData<'LegalCaseChatMessage'> & {
  id: string;
  message: string;
  file: CaseFile | null;
  createdAt: string;
  updatedAt: string | null;
  viewedAt: string | null;
  type: MessageType;
  owner?: MessageOwner | null;
  representation: MessageRepresentation;
};

//list of chats
export type GetListOfChatsParams = Pagination &
  Partial<{
    client: string;
    'client[]': string[];
    attorney: string;
    'attorney[]': string[];
    'legalCase.caseNumber': string;
  }>;

export type GetListOfChatsResponse = BaseData<'LegalCaseChat'> & {
  id: string;
  legalCase: Pick<GetMyLegalCasesResponse, 'id' | '@id' | '@type' | 'caseNumber'> &
    Pick<GetMyLegalCaseDetailsResponse, 'onConsultation' | 'files'>;
  client: MessageOwner;
  attorney: MessageOwner;
  createdAt: string;
  updatedAt: string;
  countUnwatchedMessages: number;
  lastMessage: CreateLegalCaseMessageResponse;
  active: boolean;
};

//chats with users
export type GetUsersChats = MessageOwner & {
  chatsCount: number;
  chatsMessagesCount: number;
  unwatchedChatsMessagesCount: number;
  chatLastMessage: CreateLegalCaseMessageResponse;
};

export type GetUsersChatsParams = GetMyClientsParams & {
  'userProfile.firstName'?: string;
  'userProfile.lastName'?: string;
};

export type GetChatFilesParams = Partial<
  Pagination & {
    type: string;
    'type[]': string[];
  }
>;

type LegalCaseChatByIdResponse = BaseData<'LegalCaseChat'> & {
  id: string;
  legalCase: Pick<GetMyLegalCasesResponse, 'id' | '@id' | '@type' | 'caseNumber' | 'stage'>;
  client: MessageOwner;
  attorney: MessageOwner;
};

export const chatApiService = () => {
  const makeViewedLegalCaseChatMessage = async (messageId: string) =>
    await backendApiClient({
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    }).patch<CreateLegalCaseChatResponse>(`/legal_case_chat_messages/${messageId}/viewed`, {});

  const createLegalCaseChatMessage = async (payload: CreateLegalCaseMessagePayload) =>
    await backendApiClient({
      headers: {
        Encrypted: 'Encrypted',
      },
    }).post<CreateLegalCaseMessageResponse>('/legal_case_chat_messages', payload);

  const getLegalCaseChatMessages = async (chatId: string, params?: Pagination) =>
    await backendApiClient({
      headers: {
        Encrypted: 'Encrypted',
      },
    }).get<ListOfItems<CreateLegalCaseMessageResponse[]>>(`${BASE_LEGAL_CASE_CHAT_API_URL}/${chatId}/messages`, {
      params,
    });

  const deleteLegalCaseChatMessage = async (messageId: string, params?: Pagination) =>
    await backendApiClient({}).delete(`/legal_case_chat_messages/${messageId}`);

  //list of chats
  const getUsersChats = async (params?: GetUsersChatsParams) =>
    await backendApiClient({ headers: ENCRYPTED_HEADER }).get<ListOfItems<GetUsersChats[]>>('/users/chats', {
      params,
    });

  const getListOfChats = async (params?: GetListOfChatsParams) =>
    await backendApiClient({ headers: ENCRYPTED_HEADER }).get<ListOfItems<GetListOfChatsResponse[]>>(
      BASE_LEGAL_CASE_CHAT_API_URL,
      {
        params,
      }
    );

  const getChatById = async (chatId: string) =>
    await backendApiClient({ headers: ENCRYPTED_HEADER }).get<LegalCaseChatByIdResponse>(
      `${BASE_LEGAL_CASE_CHAT_API_URL}/${chatId}`
    );

  const getChatFiles = async (chatId: string, params: GetChatFilesParams) =>
    await backendApiClient({ headers: ENCRYPTED_HEADER }).get<ListOfItems<CaseFile[]>>(
      `${BASE_LEGAL_CASE_CHAT_API_URL}/${chatId}/files`,
      { params }
    );

  return {
    makeViewedLegalCaseChatMessage,
    getUsersChats,
    getListOfChats,
    getLegalCaseChatMessages,
    createLegalCaseChatMessage,
    deleteLegalCaseChatMessage,
    getChatById,
    getChatFiles,
  };
};
