import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';

// @mui material components
import Grid, { GridTypeMap } from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components

import { setLayout, useMaterialUIController } from 'context';
import { BasePageLayout, useRouter } from 'features/common';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { AuthFlowBackgrounds } from 'features/auth/variables/illustartion';
import Footer from 'layouts/authentication/components/Footer';
import { SxProps, Theme } from '@mui/material';
import { GridProps } from '@mui/system';

// Images
const brandBlue = '/images/logo-lb-small-blue.svg';

type IllustrationLayoutProps = PropsWithChildren<{
  header?: ReactNode;
  title?: string;
  description?: string;
  children: ReactNode;
  illustration?: AuthFlowBackgrounds | string;
  onClickBack?: () => void;
  illustrationLogo?: ReactNode;
  contentWrapperProps?: GridTypeMap['props'];
}>;

export const IllustrationLayout: FC<IllustrationLayoutProps> = ({
  header,
  title,
  description,
  illustration,
  children,
  onClickBack,
  illustrationLogo,
  contentWrapperProps,
}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const { pathname } = useRouter();

  useEffect(() => {
    setLayout(dispatch, 'auth');
  }, [pathname, dispatch]);

  return (
    <BasePageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) => (darkMode ? background.default : white.main),
        }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            position: 'relative',
            backgroundColor: ({ palette: { background } }) => (darkMode ? background.default : background.default),
          }}
        >
          <MDBox
            display={{ xs: 'none', lg: 'flex' }}
            width="100%"
            component="div"
            sx={{
              background: `url(${illustration || AuthFlowBackgrounds.BRAND_LOGO}) no-repeat center center`,
              height: 1,
              width: 1,
              backgroundSize: 'cover',
            }}
          >
            {illustrationLogo || null}
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={6} sx={{ mx: 'auto' }}>
          <MDBox display="flex" flexDirection="column" alignItems="center" minHeight="100vh" position="relative">
            <MDBox
              component="p"
              px={3}
              py={4}
              sx={{ position: 'absolute', top: 0, left: 0, display: { xs: 'block', lg: 'none' } }}
            >
              <img src={brandBlue} alt="Legal Beagle logo" width={80} height={80} style={{ display: 'block' }} />
            </MDBox>
            <Grid
              container
              sx={{
                alignItems: 'center',
                pt: 13,
                flex: 1,
              }}
            >
              <Grid item xs={11} sm={8} md={8} lg={9} xl={7} mx="auto" {...contentWrapperProps}>
                <MDBox py={3} px={3} textAlign="center">
                  {!header ? (
                    <>
                      <MDBox mb={1} textAlign="center">
                        <MDTypography variant="h4" fontWeight="bold">
                          {title}
                        </MDTypography>
                      </MDBox>
                      <MDTypography variant="body2" color="text">
                        {description}
                      </MDTypography>
                    </>
                  ) : (
                    header
                  )}
                </MDBox>
                <MDBox p={3}>{children}</MDBox>
              </Grid>
            </Grid>
            {onClickBack && (
              <MDBox width="100%" px={3} pt={1} pb={{ xs: 1, lg: 4 }}>
                <Icon
                  fontSize="large"
                  color="inherit"
                  sx={{ verticalAlign: 'middle', mr: -0.5, cursor: 'pointer' }}
                  onClick={() => onClickBack()}
                >
                  west
                </Icon>
              </MDBox>
            )}
            <MDBox
              sx={{
                display: 'flex',
                width: 1,
                mb: 4,
              }}
            >
              <Footer />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </BasePageLayout>
  );
};

// Declaring default props for IllustrationLayout
IllustrationLayout.defaultProps = {
  header: '',
  title: '',
  description: '',
  illustration: '',
};
