import { FC, memo } from 'react';
import { Card, IconButton, Stack } from '@mui/material';
import { FileUploadOutlined, NearMeOutlined } from '@mui/icons-material';
import { BaseFormField } from 'features/common';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import rgba from 'assets/theme/functions/rgba';
import { ChatInputStyles as Styled } from './ChatInput.styles';

type ChatInputProps = {
  onEnterPressHandler: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeInputHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSendMessageButtonHandler: () => Promise<void>;
  onDocumentsButtonClickHandler?: () => void;
  inputValue: string;
  isFetchingNewMessages?: boolean;
  isDisabled?: boolean;
};

export const ChatInput: FC<ChatInputProps> = memo(
  ({
    onDocumentsButtonClickHandler,
    onChangeInputHandler,
    onSendMessageButtonHandler,
    onEnterPressHandler,
    inputValue,
    isFetchingNewMessages,
    isDisabled,
  }) => {
    return (
      <Card
        sx={{
          padding: 2,
          width: 1,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {onDocumentsButtonClickHandler && (
            <IconButton disabled={isDisabled} sx={Styled.IconWrapperSX} onClick={onDocumentsButtonClickHandler}>
              <FileUploadOutlined fontSize="medium" color="secondary" />
            </IconButton>
          )}
          <MDBox
            sx={{
              flex: 1,
              label: {
                lineHeight: 1,
              },
            }}
          >
            <BaseFormField
              formFieldProps={{
                pb: 0,
              }}
              formInputProps={{
                value: inputValue,
                onChange: onChangeInputHandler,
                onKeyPress: onEnterPressHandler,
                type: 'text',
                label: 'Type',
                placeholder: 'Enter your message',
                fullWidth: true,
                disabled: isDisabled,
              }}
            />
          </MDBox>
          <MDButton
            size="medium"
            iconOnly
            disabled={!inputValue.trim() || isDisabled}
            isLoading={isFetchingNewMessages}
            sx={{
              ...Styled.IconWrapperSX,
              border: theme => `1px solid ${theme.palette.info.main}`,
              ':hover': {
                backgroundColor: theme => rgba(theme.palette.info.light, 0.1),
              },
              '& svg': {
                fontSize: '20px !important',
              },
              '& span': {
                color: ({ palette }) => `${palette.info.main} !important`,
              },
            }}
            onClick={onSendMessageButtonHandler}
          >
            <NearMeOutlined fontSize="medium" color="info" />
          </MDButton>
        </Stack>
      </Card>
    );
  }
);
