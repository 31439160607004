import { useEffect, useState } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';

// Material Dashboard 2 PRO React TS components
import { Apple } from '@mui/icons-material';
import {
  FIREBASE_PROVIDERS,
  FirebaseProvider,
  OAUTH_PROVIDER_TYPES,
  baseFirebaseAuthErrorHandler,
  createFirebaseService,
  firebaseAuth,
} from 'features/auth';
import GoogleLogo from 'assets/images/logo-google.svg';
import { ToastType, notice } from 'features/common';
import { FirebaseError } from 'firebase/app';
import { useFirebaseUser } from 'features/auth/contexts/FirebaseUserContextProvider';

export const Accounts = (): JSX.Element => {
  const { firebaseUser } = useFirebaseUser();
  const { linkWithProvider, unlinkWithProvider } = createFirebaseService();
  const [googleAuth, setGoogleAuth] = useState<boolean>(false);
  const [appleAuth, setAppleAuth] = useState<boolean>(false);

  const handleLinkAuth = async (
    provider: OAUTH_PROVIDER_TYPES,
    value: boolean,
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (value) {
      if (firebaseUser.providerData.length <= 1) {
        notice(
          ToastType.WARNING,
          'You can`t change your the only one auth provider, please connect another one and try again.'
        );
        return;
      }
      await unlinkWithProvider(FIREBASE_PROVIDERS[provider])
        .catch(error => {
          if (error instanceof FirebaseError) {
            return baseFirebaseAuthErrorHandler(error, provider);
          }
        })

        .then(response => {
          if (response) setter(!value);
        });
    } else {
      await linkWithProvider(provider)
        .catch(error => {
          if (error instanceof FirebaseError) {
            return baseFirebaseAuthErrorHandler(error, provider);
          }
        })
        .then(response => {
          if (response) setter(!value);
        });
    }
  };

  const connectProviders = () => {
    firebaseAuth?.currentUser?.providerData?.map((provider: FirebaseProvider) => {
      switch (provider.providerId) {
        case 'google.com':
          setGoogleAuth(true);
          break;
        case 'apple.com':
          setAppleAuth(true);
          break;
        default:
          return;
      }
    });
  };

  useEffect(() => {
    connectProviders();
  }, []);

  return (
    <Card id="accounts">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Linked Accounts</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Connect your Google or Apple accounts to enable seamless login and simplify access to your profile.
        </MDTypography>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <MDBox display="flex" alignItems="center">
            <MDAvatar src={GoogleLogo} variant="rounded" alt="Google logo" sx={{ width: 30, height: 30 }} />
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                Google
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={{ xs: '100%', sm: 'auto' }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              <MDTypography variant="button" color="text">
                {googleAuth ? 'Enabled' : 'Disabled'}
              </MDTypography>
            </MDBox>
            <MDBox mr={1}>
              <Switch
                checked={googleAuth}
                onChange={() => handleLinkAuth(OAUTH_PROVIDER_TYPES.GOOGLE, googleAuth, setGoogleAuth)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <MDBox display="flex" alignItems="center">
            <Apple sx={{ width: 30, height: 30 }} />
            <MDBox ml={2} lineHeight={0}>
              <MDTypography variant="h5" fontWeight="medium">
                Apple
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={{ xs: '100%', sm: 'auto' }}
            mt={{ xs: 1, sm: 0 }}
          >
            <MDBox lineHeight={0} mx={2}>
              <MDTypography variant="button" color="text">
                {appleAuth ? 'Enabled' : 'Disabled'}
              </MDTypography>
            </MDBox>
            <MDBox mr={1}>
              <Switch
                checked={appleAuth}
                onChange={() => handleLinkAuth(OAUTH_PROVIDER_TYPES.APPLE, appleAuth, setAppleAuth)}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};
