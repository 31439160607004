import {
  backendApiClient,
  BaseData,
  GetAttorneyProfileResponse,
  GetWorkingHoursResponse,
  ListOfItems,
  PATCH_HEADERS,
} from '../index';
import { CreateBaseUserProfileSchema, LBUserRole, UserWorkType } from '../../auth';
import { BackendUserResponse, Logo } from './types';
import { CompanyInfo } from 'features/company-list';
import { GetUserReviewParams, GetUserReviewsResponse } from 'features/review/services';

type CreateBackendUserRequestBody = {
  email: string;
  phone?: string;
  userWorkType: UserWorkType;
  userProfile: CreateBaseUserProfileSchema;
};

type CreateLogoRequestPayload = {
  logo: string;
};

export type GetUserByIdResponse = Omit<BackendUserResponse, 'attorneyProfile'> & {
  attorneyProfile:
    | (Omit<GetAttorneyProfileResponse, 'workingHours'> & {
        workingHours: GetWorkingHoursResponse;
      })
    | null;
  company:
    | (BaseData<'Company'> &
        CompanyInfo & {
          logo?: Logo | null;
        })
    | null;
};

type CheckPinCodeAccessPayload = Pick<CreatePinCodePayload, 'pinCode'> & {
  firebaseUuid: string;
  deviceUuid: string;
  pushToken?: string;
};

type CheckPinCodeAccessResponse = BaseData<''> & {
  token: string;
};

type RegisterDevicePayload = {
  platformType: 'web';
  deviceId: string;
};

type CreatePinCodePayload = {
  pinCode: string;
  pinCodeConfirm: string;
};

export type RetrieveTokenPayload = {
  secretCode: string;
};

export const createBackendUserService = () => {
  const createBackedUser = async ({ email, phone, userWorkType, userProfile }: CreateBackendUserRequestBody) => {
    try {
      const createdUser = await backendApiClient({}).post<BackendUserResponse>('/users', {
        email,
        phone: phone ?? '-',
        userProfile: {
          personTitle: userProfile.personTitle,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          country: '/countries/US',
          state: userProfile.state,
          town: userProfile.town,
        },
        roles: [LBUserRole[userWorkType]],
      });
      return createdUser;
    } catch (error) {
      throw error;
    }
  };

  const updateBackendUser = async (firebaseUserId: string, payload: Partial<CreateBackendUserRequestBody>) =>
    await backendApiClient({}).patch<BackendUserResponse>(
      `/users/${firebaseUserId}`,
      {
        phone: payload?.phone ?? '-',
      },
      {
        headers: PATCH_HEADERS,
      }
    );

  const getBackendUser = async (): Promise<BackendUserResponse> => {
    const { data } = await backendApiClient({}).get<BackendUserResponse>(`/security/profile`);

    return data;
  };

  const putBackendUserLogo = async (
    firebaseId: string,
    data: CreateLogoRequestPayload
  ): Promise<BackendUserResponse> => {
    try {
      const { data: user } = await backendApiClient({ headers: PATCH_HEADERS }).patch<BackendUserResponse>(
        `/users/${firebaseId}/logo`,
        data
      );

      return user;
    } catch (error) {
      console.error({ error });
    }
  };

  const getUserById = async (firebaseUserId: string) =>
    await backendApiClient({}).get<GetUserByIdResponse>(`/users/${firebaseUserId}`);

  const getUserRating = async (firebaseUserId: string, params: GetUserReviewParams) =>
    await backendApiClient({}).get<ListOfItems<GetUserReviewsResponse[]>>(`/users/${firebaseUserId}/reviews`, {
      params,
    });

  const connectPushNotification = async (token: string) =>
    await backendApiClient({}).post<BaseData<'UserPushNotificationToken'>>('/security/push_notification', {
      token,
      platformType: 'web',
      pushType: 'push',
    });

  const createUserPinCode = async (payload: CreatePinCodePayload) =>
    await backendApiClient({}).post('/user/profile/pin_code', payload);

  const checkPinCodeAccess = async (payload: CheckPinCodeAccessPayload) =>
    await backendApiClient({}).post<CheckPinCodeAccessResponse>('/user/profile/pin_code/check_access', payload);

  const deactivateUserPinCode = async () => await backendApiClient({}).post('/user/profile/pin_code/deactivate', {});

  const registerDevice = async (payload: RegisterDevicePayload) =>
    await backendApiClient({}).post('/security/profile/meta/add_device', payload);

  const logoutBackendUser = async (tokens: string[], enablePushForMyself?: boolean) =>
    await backendApiClient({}).post('/security/logout', {
      tokens,
      ...(!enablePushForMyself ? { enablePush: enablePushForMyself } : {}),
    });

  const retrieveToken = async (payload: RetrieveTokenPayload) =>
    await backendApiClient({}).post<CheckPinCodeAccessResponse>('/security/service_login', payload);

  return {
    retrieveToken,
    connectPushNotification,
    createBackedUser,
    getBackendUser,
    putBackendUserLogo,
    getUserById,
    getUserRating,
    updateBackendUser,
    createUserPinCode,
    checkPinCodeAccess,
    deactivateUserPinCode,
    registerDevice,
    logoutBackendUser,
  };
};
