import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useMaterialUIController, setLayout } from 'context';
import {
  BackendUserAttorneyProfile,
  MercureLinkMap,
  ToastType,
  createBackendUserService,
  notice,
  useRouter,
  useUser,
} from 'features/common';
import { isSupportedNotificationApi, requestPermission } from 'features/auth';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useNotificationsCenter } from 'features/store';
import DashboardNavbar from '../components/DashboardNavbar';
import { paymentRequiredErrorHandler } from '../errorHanders';
import { MobileNotificationAlert } from '../components/MobileNotificationAlert';
import { useMercure } from '../hooks/useMercure';
import { PINcodeAlert } from '../components/PINcodeAlert';

type DashboardLayoutProps = PropsWithChildren & {
  isNavigationDisabled?: boolean;
};

export const DashboardLayout: FC<DashboardLayoutProps> = ({ children, isNavigationDisabled }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useRouter();
  const { backendUser, isAdmin, isAttorney, connectBackendUserHandler } = useUser();

  const { connectPushNotification } = createBackendUserService();

  const { pushNotificationData, setPushNotificationId, setFirebaseToken } = useNotificationsCenter();

  //Check notifications
  const [isShowNotificationBanner, setIShowNotificationBanner] = useState(
    (isSupportedNotificationApi() && Notification.permission === 'denied') ||
      (isSupportedNotificationApi() && Notification.permission === 'default') ||
      false
  );
  const [firebaseNotificationToken, setFirebaseNotificationToken] = useState(pushNotificationData?.firebaseToken);

  const connectPushNotificationToken = async (token: string) => {
    try {
      const { data } = await connectPushNotification(token);
      setPushNotificationId(data?.['@id']);
    } catch (error) {
      paymentRequiredErrorHandler(error, 'Failed to set push notification token, please try again!');
      console.error(error);
    }
  };

  const handleFirebaseConfig = async () => {
    const token = await requestPermission();
    if (token) {
      if (token !== firebaseNotificationToken) {
        setFirebaseNotificationToken(token);
        setFirebaseToken(token);
      }
      if (isSupportedNotificationApi()) {
        Notification.permission === 'granted' && setIShowNotificationBanner(false);
      }
    }
  };

  const onClickEnablePermissionsButtonHandler = async () => {
    if (isSupportedNotificationApi()) {
      await handleFirebaseConfig();

      Notification.permission === 'denied' &&
        notice(
          ToastType.INFO,
          'To enable notifications, go to your browser settings and allow notifications for this site.'
        );
    } else {
      notice(ToastType.INFO, 'Your browser does not support push notification');
    }
  };

  useEffect(() => {
    handleFirebaseConfig();
  }, []);

  useEffect(() => {
    if (pushNotificationData?.firebaseToken !== firebaseNotificationToken && backendUser) {
      connectPushNotificationToken(pushNotificationData?.firebaseToken);
    }
  }, [backendUser, pushNotificationData.firebaseToken]);

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
  }, [pathname, dispatch]);

  // TODO conference hack
  const onReceiveEventMessageHandler = useCallback(async (event: MessageEvent) => {
    const message: BackendUserAttorneyProfile = JSON.parse(event.data);
    if (message['@type'] === 'AttorneyProfile') await connectBackendUserHandler();
  }, []);

  useMercure({
    eventId: backendUser?.attorneyProfile?.id,
    onReceiveEventHandler: onReceiveEventMessageHandler,
    mercureSubscribeURL: MercureLinkMap[process.env.REACT_APP_BASE_APP_URL || 'http://localhost:3000'],
    topicSubURL: 'attorney_profiles',
    isDissallowSubscription: !isAttorney,
  });

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        [breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {isShowNotificationBanner && (
        <MDBox
          sx={{
            display: 'flex',
            width: 1,
            height: 1,
            p: 1,
            color: 'white',
            borderRadius: '12px',
            backgroundColor: ({ palette }) => palette.error.main,
          }}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" width={1} alignItems="center">
            <MDTypography variant="body2" fontWeight={400} sx={{ color: 'white !important', textAlign: 'center' }}>
              The app needs permission to enable push notifications
            </MDTypography>
            <MDButton onClick={onClickEnablePermissionsButtonHandler}>Enable permissions</MDButton>
          </Stack>
        </MDBox>
      )}
      <MobileNotificationAlert />
      <PINcodeAlert />
      <DashboardNavbar isAdmin={isAdmin} isDisabled={isNavigationDisabled} />
      {children}
    </MDBox>
  );
};

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node,
};
